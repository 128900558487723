import React from "react";
import {NavLink} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Chip,
  Tooltip,
  Progress,
} from "@material-tailwind/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { daysAgo } from "../../../../utils/datetime";
import {
  useListInspectionCasesQuery,
  useGetCaseDetailQuery,
} from "../../../apis/endpoints/admin/inspectionServcie";

interface CasesListCardProps {
  startTime?: string;
  endTime?: string;
  projectId?: number;
  departmentId?: number;
  wbs?: string;
  status?: string;

  // rendering control 
  showHeader?: boolean;
}

export const CasesListCard :React.FC<CasesListCardProps> = ({
    startTime =  daysAgo(30).toISOString(),
    endTime= daysAgo(0).toISOString(),
    projectId,
    departmentId,
    wbs,
    status,
    showHeader = true,
}) => {

  const { data, error, isLoading } = useListInspectionCasesQuery({
    startTime: daysAgo(30).toISOString(),
    endTime: daysAgo(0).toISOString(),
    projectId,
    departmentId,
    wbs,
    status,
  });

  if (isLoading) {
    return <>...loading</>;
  }
  if (error) {
    return <>"Error: " + error.toString()</>;
  }
  console.log("cases data fetched: ", data);

  const caseData: Array<{ [key: string]: any }> = data?.json;

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
      {showHeader && (
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            Recent Cases
          </Typography>
        </CardHeader>
      )}
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "tags",
                  "case #",
                  "status",
                  "created at",
                  "updated at",
                  "related chat",
                  "description"
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {caseData.map((row, index) => {
                const className = `py-3 px-5 ${
                  index === caseData.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`;

                return (
                  <tr key={index}>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.tags}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        <NavLink to={`/dashboard/case/${row.id}`} 
                          className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium"
                        >
                            {row.id}
                        </NavLink>
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.status}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.created_at}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.updated_at}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        <NavLink to={`/dashboard/chatdetail/${row.chat_id}`} 
                          className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium"
                        >
                            {row.chat_id}
                        </NavLink>
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.description}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {caseData.length === 0 && (
            <div className="flex flex-col items-center justify-center gap-8 py-12 min-h-500px">
              No cases found in specified time range and filter criteria.
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
