import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import layoutReducer from '../features/altlayout/layoutSlice';
import chatsReducer from '../features/chats/chatRecordSlice';
import authReducer from '../auth/authSlice';
// import { adminApi } from '../features/apis/adminEndpoints';

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    auth: authReducer,
    layout: layoutReducer,
    chats: chatsReducer,

    // the RTK integration point 
    // [adminApi.reducerPath]: adminApi.reducer,
  },

  // devTools: import.meta.env.DEV, 
  devTools: true, 

  // add api middleware enables caching, invalidation, pooling, etc.
  // middleware: (getDefaultMiddleware) => 
  //   getDefaultMiddleware().concat(adminApi.middleware),
  
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
