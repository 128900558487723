import { useState } from 'react';
import {Card,CardContent} from '@mui/material'; 
import {FilterList, FilterListItem, FilterLiveSearch, useGetList} from 'react-admin';

import PersonOffIcon from '@mui/icons-material/PersonOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import PatternIcon from '@mui/icons-material/Pattern';

//TODO: will have i18n support later on, no such need for demo yet

const Aside = () => {

    // No need for customized selected and toggle implementation here, by taking
    // a look at new version's source, it seems that the default selector and 
    // toggle related code already use lodash's _.matches() and _pickBy() to
    // have very decent implementation, and it called useListFilterContext() to 
    // obtain direct access to the parent list context already. Based that, 
    // following behvaiors should work:
    // - no longer need things like {with_ssn: 1, with_email: undefined, ,,,}} 
    //   to manuall define the filter strcuture, like many of its demo/doc did
    // - for simple selection, just keep the key value same, then the system 
    //   will automatically toggle and overwrite.
    // - for multi section, the simplest way is to keep related but differnt 
    //   keys, and they will all show up in the final fitler. And when you 
    //   deselect, they will be removed just like single selection did. 

    // Based on that, 

    // const [selectedItems, setSelectedItems] = useState<string[]>([]);

    // const handleSelect = (item: string) => {
    //     if (selectedItems.includes(item)) {
    //         setSelectedItems(selectedItems.filter((i) => i !== item));
    //     } else {
    //         setSelectedItems([...selectedItems, item]);
    //     }
    // };

    // const isSelected = (value: any, filters: any) =>{ 
    //     console.log("in isSelected: ", value, filters)
    //     return selectedItems.includes(value.category);
    // };

    // The relationship between each section (FilterList) is "AND", inside each 
    // section, the relationship between each item (FilterListItem) is "OR" for
    // multiselection.  

    return (
        <Card sx={{order: -1, mr: 2, mt:8, width: 250}}>
            <CardContent>
                <div>Quick Filters</div>
                <FilterList label="Personal Info" icon={<PersonOffIcon/>}>
                    <FilterListItem 
                        label="SSN" 
                        // value={{with_ssn: 1, with_email: undefined, with_address: undefined}} />
                        value={{with_ssn: 1}} />
                    <FilterListItem 
                        label="Email" 
                        // value={{with_ssn: undefined, with_email: 1, with_address: undefined}} />
                        value={{with_email: 1}} />
                    <FilterListItem 
                        label="Adddress" 
                        // value={{with_ssn: undefined, with_email: undefined, with_address: 1}} />
                        value={{with_address: 1}} />
                    <FilterListItem 
                        label="Phone" 
                        value={{with_phone: 1}} />
                    <FilterListItem 
                        label="Username" 
                        value={{with_username: 1}} />
                    <FilterListItem 
                        label="Password" 
                        value={{with_password: 1}} />
                    <FilterListItem 
                        label="Sensitive Term" 
                        value={{with_sensitive_term: 1}} />
                </FilterList>

                {/* 
                It is relative cheap for ChatGPT, now 0.002/1k token, 
                but GPT-4 is quite different, its pricing depends on the context 
                and the prompt/completion. 
                - 8K context,{prompt: 0.03, completion:0.06}, 
                - 32k context, {prompt: 0.06, completion: 0.12}  

                Say, chatgpt gpt-3.5-turbo cheap option, with 4k limit, max one
                req it can get 0.002 * 4 = 0.008. 
                However, if it is a conversation, then each call like that will 
                cost a lot of money, it will error out and reset anyway due to 
                conext overflow. At that point, summary or redo context is needed.
                */}
                <FilterList label="Cost Insight" icon={<AttachMoneyIcon/>}>
                    <FilterListItem 
                        label="> 0.02" 
                        value={{cost_gt: 0.002}} />
                    <FilterListItem 
                        label="> 0.04" 
                        value={{cost_gt: 0.004 }} />
                    <FilterListItem 
                        label="> 0.06" 
                        value={{cost_gt: 0.006}} />
                </FilterList>

                <FilterList label="Model" icon={<ModelTrainingIcon/>}>
                    <FilterListItem 
                        label="gpt-3.5-turbo" 
                        value={{use_gpt35turbo:1}} />
                    <FilterListItem 
                        label="gpt-4.0" 
                        value={{use_gpt40:1}} />
                </FilterList>
            </CardContent>
        </Card>
    )
}

export default Aside;