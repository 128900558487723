/**
 * Redux slice for authentication, since we will support multiple auth providers,
 * it could be easier to manage it from centrla state, as the jwt token can be 
 * global state shared by all components. No need to maintain layers and layers of 
 * context providers.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { useNavigate } from "react-router-dom";
import { useAuthProvider } from 'react-admin';

export interface AuthState {
  authProvider: string;
  isAuthenticated: boolean;
  idToken: string;
  isAdmin: boolean;
}


const initialState: AuthState = {
    authProvider: '',
    isAuthenticated: false,
    idToken: '',
    isAdmin: false,
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthProvider: (state, action: PayloadAction<string>) => {
            state.authProvider = action.payload;
        },

        setIdToken: (state, action: PayloadAction<string>) => {
            state.idToken = action.payload;
        },

        //set isAuthenticated 
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        }
    },
});


export const { setAuthProvider, setIdToken, setIsAuthenticated } = authSlice.actions;

export const selectIdToken = (state: RootState) => state.auth.idToken;

export default authSlice.reducer;