
import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Switch,
  Typography,
} from "@material-tailwind/react";


export const ClaudeSetting = () => {

    return (<>
    
    <div className="gird-cols-1 mb-12 grid gap-12 px-4  p-16 shadow-lg rounded-lg">
        <div className="ml-8">
            <Typography variant="h6" color="blue-gray" className="mb-3">
                Claude API Settings
            </Typography>

            <div className="mb-8 ">
                <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
                Anthropic Claude has two versions: <strong>claude-instant-1</strong> and <strong>claude-2</strong>. 
                Only the latest claude-2 will be supported by our platform.
                <br/>
                There are less tuning points in terms of parameters, we will only have default max token setting here, 
                and let the API clien to pass other params (such as stop sequence) directly. 
                <br/>
                Technically, the newly released claude-2 can support max 100,000 tokens, which is too big for typical 
                enterprise usage, and limit can be put here for cost control.
                </Typography>
            </div>

            <div className="flex flex-col gap-6 w-full">
                <Typography className="mb-1 block text-xs font-semibold  text-blue-gray-500">
                    max_tokens_to_sample: the maximum number of tokens to generate before stopping 
                </Typography>
                <Input disabled={true} labelProps={{shrink:false, visuallyHidden: false,}} label="max_toens_to_sample" value="10240" />
            </div>

            <NavLink to="/dashboard/settings" >
                <Button variant="gradient" className="mt-16" >
                Back 
                </Button>

                {/* <Typography
                  as="span"
                  variant="small"
                  color="blue"
                  className="ml-1 font-bold"
                >
                 Back
                </Typography> */}

            </NavLink>
        </div>
    </div>
    </>);
}