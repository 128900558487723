/** 
 * Organization related services to fetch WBS, project and department data.
 */
import { compact} from "lodash";
import {useQuery} from "react-query";
import {fetchJson} from "../../../../auth/DataProvider" 
import { Session } from "inspector";
import {daysAgo, roundToMinute } from "../../../../utils/datetime";


export const useGetDepartmentListQuery = () => {

    return useQuery(
        ["department", "list"],
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/departments`,
            { method: 'GET' }
        )
    );
};


export const useGetProjectListQuery = () => {

    return useQuery(
        ["project", "list"],
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/projects`,
            { method: 'GET' }
        )
    );
};


export const useGetDepartmentChatStatListQuery = ({
    startTime = daysAgo(30).toISOString(),
    endTime = daysAgo(0).toISOString(),
}) => {
    const _start = roundToMinute(new Date(startTime), 10).toISOString();
    const _end  = roundToMinute(new Date(endTime), 10).toISOString();
    return useQuery(
        ["department", "chat_stat", _start, _end],
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/departments/stat?start_time=${_start}&end_time=${_end}`,
            { method: 'GET' }
        )
    )
}


export const useGetProjectChatStatListQuery = ({
    startTime = daysAgo(30).toISOString(),
    endTime = daysAgo(0).toISOString(),
}) => {
    const _start = roundToMinute(new Date(startTime), 10).toISOString();
    const _end  = roundToMinute(new Date(endTime), 10).toISOString();
    return useQuery(
        ["project", "chat_stat", _start, _end],
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/projects/stat?start_time=${_start}&end_time=${_end}`,
            { method: 'GET' }
        )
    )
}



export const useWBSQuery = () => {

    return useQuery(
        // cache based on datetime range and only to minute level  
        ['wbs'],
        // the fetcher function
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/wbs`,
            { method: 'GET' }
        )
    )
}


interface SessionFilterProps {
    wbs?: string;
    projectId?: string;
    departmentId?: string;
    startTime?: string;
    endTime?: string;
}

export const useGetSessionListQuery = ({
    wbs,
    projectId,
    departmentId,
    startTime = daysAgo(90).toISOString(), 
    endTime = daysAgo(0).toISOString(),
}: SessionFilterProps) => {
   
    const _startTime = roundToMinute(new Date(startTime));
    const _endTime = roundToMinute(new Date(endTime));

    const _wbsQstr = wbs ? `wbs=${wbs}` : '';
    const _projQstr = projectId ? `project_id=${projectId}` : '';
    const _deptQstr = departmentId ? `department_id=${departmentId}` : '';

    const _qstr = compact([_wbsQstr, _projQstr, _deptQstr]).join('&');
    
    return useQuery(
        // cache based on datetime range and only to minute level  
        ['session for wbs', wbs, _startTime, _endTime],
        // the fetcher function
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/sessions?start_time=${startTime}&end_time=${endTime}&${_qstr}`,
            { method: 'GET' }
        )
    )

}
