/** 
 * Still keep this before move auth to centralized auth slice of Redux. 
 * May become obsolete in the future.
 */
import {store} from '../app/store'; 
import { useSelector, useDispatch } from "react-redux";
import { setIdToken, setIsAuthenticated } from './authSlice';

// NOTE: typical localstorage based JWT token handling is not very safe, now 
//       use in mem implementation.
//       Also, since fetchUtil from react-admin expects undefined, not null, so
//      we use undefined here. 
// let _InMemJwtToken: string | null = null;
let _InMemJwtToken: string | undefined = undefined;

export function getToken() {
    // in the future, we will grab from rootState.auth.idToken directly
   return _InMemJwtToken; 
}


export function setToken(token:string) {
    // typically this is called when after successful login
    if (token) {
        store.dispatch(setIsAuthenticated(true));
    }
    store.dispatch(setIdToken(token));
    _InMemJwtToken = token;
    return true; 
}


export function removeToken() { 
    // _InMemJwtToken = null; 
    _InMemJwtToken = undefined; 
    store.dispatch(setIsAuthenticated(false));
    store.dispatch(setIdToken(''));
    return true;
}

// export default {  getToken, setToken, eareazeToken}