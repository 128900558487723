import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Chip,
  Tooltip,
  Progress,
} from "@material-tailwind/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { daysAgo } from "../../../utils/datetime";
import { useEventQuery } from "../../apis/endpoints/admin/eventService";

// Get it done first, may extract to generic component later, so comment out fieldNames for now
export interface EventCardProps {
  title: string;
  startTime?: string;
  endTime?: string;
  eventTypes?: string[];
}

export const EventCard: React.FC<EventCardProps> = ({
  title,
  startTime = daysAgo(7).toISOString(),
  endTime = daysAgo(0).toISOString(),
  eventTypes = ["WARNING", "ERROR", "INFO"],
}) => {
  //   data: Array<{ [key: string]: any }>;
  const { data, error, isLoading } = useEventQuery({
    startTime,
    endTime,
    eventTypes,
  });

  if (isLoading) {
    return <>...loading</>;
  }
  if (error) {
    return <>"Error: " + error.toString()</>;
  }
  console.log("data fetched: ", data);

  const eventData: Array<{ [key: string]: any }> = data?.json;

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            {title}
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "event type",
                  "event time",
                  "event name",
                  "event source",
                  "event message",
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {eventData.map((row, index) => {
                const className = `py-3 px-5 ${
                  index === eventData.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`;

                return (
                  <tr key={index}>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        <Chip
                          variant="gradient"
                          color={
                            row.event_type === "WARNING" ? "green" : "blue-gray"
                          }
                          value={row.event_type}
                          className="py-0.5 px-2 text-[11px] font-medium"
                        />
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.event_time}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.event_name}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.event_source}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.event_message}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

        {eventData.length === 0 && (
            <div className="flex flex-col items-center justify-center gap-8 py-12 min-h-500px">
                No events found in specified time range and filter criteria.
            </div>
        )}
        </CardBody>
      </Card>
    </div>
  );
};

export default EventCard;
