import _ from 'lodash';
import { useState } from 'react';
import {
  Box, Button, Drawer, 
  Icon,
  Link, List, ListItem, ListItemText, 
  Paper,
  Tab, Tabs, 
  Table, TableContainer, TableHead, TableBody, TableRow, TableCell, tableCellClasses,
  Theme,
  styled, useMediaQuery,
} from '@mui/material';

import { friendlyName } from '../../utils/strings';

// styling from mui doc
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


interface KeyValTableProps {
    record: any;
    fields: string[]; 
    headers?: string[];
    // maybe there are cases where key display also relies on values, for 
    // instance, if value is "true", turn key display to green, etc.
    keyhandler?: (key: string, value: any) => JSX.Element;
    valhandler?: (key: string, value: any) => JSX.Element;
}


const KeyValTable: React.FC<KeyValTableProps> 
    = ({record, 
        fields, 
        headers = ["Info", "Value"], 
        keyhandler = (key, value) => <>{friendlyName(key)}</>,
        valhandler = (key, value) => <>{value}</>,
    }) => {
    var _sourceList = [];
    var _keyName: string = "";
    var _valueName: string = ""; 

    if(_.isArray(record)){
       _sourceList = record; 
       [_keyName, _valueName] = fields;
    }else{ 
        // this will result in a list like [{field: "xxx", value: "yyy"}, ...] 
        _sourceList = fields.map((field) => ({ field, value: record[field] }));
        [_keyName, _valueName] = ["field", "value"];
    };

    console.log("in KeyValTable, _sourceList is: ", _sourceList, " _keyName: ", _keyName, " _valueName: ", _valueName);

    return (
        <TableContainer component={Paper} sx={{ mt: 2, ml: 1, mr: 2 }}>
        <Table sx={{ minWidth: 100 }} aria-label="customized table">
            <TableHead>
            <TableRow>
                { headers.map((field) => (
                    <StyledTableCell key={field}>{field}</StyledTableCell>
                ))}
            </TableRow>
            </TableHead>
            <TableBody>
                { _sourceList.map((row: any) => (
                    <StyledTableRow 
                      // mostly view only, and _keyname is not unique, remove key attribute for now
                      // key={row[_keyName]}
                    >
                        <StyledTableCell component="th" scope="row">
                            { keyhandler(row[_keyName], row[_valueName]) }
                        </StyledTableCell>
                        <StyledTableCell > 
                            { valhandler(row[_keyName], row[_valueName])}
                            </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
        </TableContainer>
    )
}

export default KeyValTable;