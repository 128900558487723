/**
 * Export data and allow user to download it as a file.
 */

export const downloadJSON = (
  data: NonNullable<object>,
  downloadFileName: string = "data.json"
) => {
  const element = document.createElement("a");
  const file = new Blob([JSON.stringify(data)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(file);

  element.href = url;
  element.download = downloadFileName;
  document.body.appendChild(element);
  element.click();

  // After clicking, delete the element, to avoid memory leaks. Howwever speical handling is needed for Firefox.
  // see https://stackoverflow.com/questions/30694453/blob-createobjecturl-download-not-working-in-firefox-but-works-when-debugging
  setTimeout(() => {
    document.body.removeChild(element);
    URL.revokeObjectURL(url);
  }, 100);
};
