import React from "react";
import {StatCard} from "./StatCard";
import { daysAgo } from "../../../utils/datetime";
import {StatCardProps} from "./StatCard";

import {useQuery} from "react-query";
import {fetchJson} from "../../../auth/DataProvider";

import { useMetricsQuery } from "../../apis/endpoints/admin/metricsService";

export interface MetricsStatCardProps extends StatCardProps {
    chartType?: string;
    startTime?: string;
    endTime?: string;
    dimension?: string;
    metrics: string[];
}

//TODO: get rid of all these in the future, it is different map than charts as well
const metricsMap: {[key:string]: string} = {
    "total_tokens": "total_tokens_sum",
    "user": "user_distinct_count",
    "session": "session_distinct_count",
    "request": "request_count"
}

export const MetricsStatCard: React.FC<MetricsStatCardProps> = ({
    startTime = daysAgo(30).toISOString(),
    endTime = daysAgo(0).toISOString(),
    dimension,
    metrics,
    ...rest
}) => {

    const {data, error, isLoading} = useMetricsQuery({startTime, endTime, metrics, dimension});

    if(isLoading) return <div>Loading...</div>;
    if(error) return <div>Error: {error.toString()}</div>;

    console.log(">>>> card data: " , data, " error: ", error, " isLoading: ", isLoading);

    // const  items =  data?.json;
    // const _total_sum = items.reduce((a: number, b:any)=> a + (b.total_tokens_sum || 0), 0);
//   [
//   {
//     "date": "2023-04-02 08:00:00",
//     "total_tokens_sum": 408
//   },
//   {
//     "date": "2023-04-06 08:00:00",
//     "total_tokens_sum": null
//   },

    // if dimension is optional, we will get total token sum directly
    const colName = metricsMap[metrics[0]];
    //const value = data?.json[0].total_tokens_sum; 
    const value = data?.json[0][colName];

    return (
        <StatCard {...rest} value={value} />
    )
}
