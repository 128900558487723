import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Switch,
  Typography,
} from "@material-tailwind/react";


export const BardSetting = () => {

    return (<>
    
    <div className="gird-cols-1 mb-12 grid gap-12 px-4  p-16 shadow-lg rounded-lg">
        <div className="ml-8">

            <Typography variant="h6" color="blue-gray" className="mb-3">
                Bard API Settings
            </Typography>

            <div className="mb-8 ">
                <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
                Bard official API is not open to public yet.
                </Typography>
            </div>

        </div>

    <NavLink to="/dashboard/settings" >
        <Button variant="gradient" className="mt-1 ml-6" >
        Back 
        </Button>
    </NavLink>
    </div>
    </>);
}