import { Datagrid, useRecordContext } from 'react-admin';
import {Box, Typography, useMediaQuery, Theme} from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';

// Not all filters can get back valid result, in case of empty search result
// we need to define a custom empty page, the default one ugly, and it does
// NOT have min height support, which makes whole UI very ugly.
const EmptyChatList = () => {
    // no real data
    return (
    <Box sx={{minHeight:'80vh' }}>
        <Typography variant='h6' sx={{mt:"3vh"}}>
            <TableViewIcon sx={{fontSize: '3.7rem', mt:'1em', pt:'2rem'} } /> 
            No chat record matches, please change the filter.
        </Typography> 
    </Box>
    )
}

export default EmptyChatList;
