import { useState } from 'react';
import {
  Box, 
  Tab, Tabs, 
} from '@mui/material';

import { 
  Button, 
  Card,
  CardBody, 
  CardFooter, 
  Checkbox, 
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";

import { 
  ArrowDownOnSquareIcon, 
  FingerPrintIcon,
} from '@heroicons/react/24/solid';

import { Routes, Route, useMatch, useParams, NavLink } from 'react-router-dom';
import { useRecordContext, useEditContext, useGetOne } from 'react-admin';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BlankPlaceHolder from '../../utils/BlankPlaceHolder';
import { JsonDebug } from '../../utils/JsonDebug';
import KeyValTable from './KeyValTable';
import { friendlyName } from '../../utils/strings';
import RoleIcon from './RoleIcon';
import {ChatNavLink} from './ChatNavLink';
import {downloadJSON } from '../../utils/export'; 
import { JSONTree } from 'react-json-tree';
import { CreateCaseForm } from '../altlayout/widgets/CreateCaseForm';

// the mui lab has some version conflict with word cloud components
// import TabPanel from '@mui/lab/TabPanel'; 

// for TypeScript, we cannot directly use implicit 'any' props of 'match' inside 
// the definition, need to go extra mile to extend it. 
// It is sad that the RouteComponentProps no longer exist in react-router-dom;
// as react-admin now use react-router v6.x, which is quite new with broken 
// changes, and we muse use new machanesim to get the match params.


const ChatDetailComponent = () => {

  // const match = useMatch('/chatrecord/:id/detail');
  // console.log("--- in ChatDetailComponent, match is: ", match);

  const {id} = useParams();
  
  // use raw dataprovider call, which will enforce specific url format
  // const { data: chatrecord, isLoading, error } = useGetOne('chatrecord', { id: match!.params.id });
  const { data: chatrecord, isLoading, error } = useGetOne('chatrecord', {id});

  // move to top level, see https://react.dev/warnings/invalid-hook-call-warning
  const [tabIndex, setTabIndex] = useState(0);

  if(isLoading) { 
    console.log("--- loading , wait... ")
    return null;
  }

  if (error) {
    return <div>ERROR</div>;
  }

  const chatId = chatrecord.id;
  const sessionId = chatrecord.session_id;
  
  console.log("-- chat record is: ", chatrecord);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };


  // const back_url = "#/chatrecord/" + chatrecord.id;
  //const back_url = "#/dashboard/chatrecord";
  const back_url = "/dashboard/chatrecord";

  const reqJson = JSON.parse(chatrecord.request);
  const rspJson = JSON.parse(chatrecord.response);
  
  const handleRequestDownload = () => {
    downloadJSON(reqJson, "request.json");
  }

  const handleRespDownload = () => {
    downloadJSON(rspJson, "response.json");
  }

  return (
    <Box>
    <Box sx={{ maxWidth: { xs: 320, sm: 480 },
               minWidth: 800,
               bgcolor: 'background.paper',
               }}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        <Tab label="General Info" />
        <Tab label="Request" />
        <Tab label="Response" />
        <Tab label="PII Info" />
        <Tab label="Sentiment" />
        <Tab label="Action" />
      </Tabs>
    </Box>

    <Box sx={{ maxWidth: { xs: 320, sm: 480 },
               bgcolor: 'background.paper',
               minHeight: '80vh',
               minWidth: '90%' }}>
        {tabIndex === 0 && (
          <Card className="pt-6  w-auto">
            <CardBody>
              <KeyValTable record={chatrecord}
                fields={[
                  "id", 
                  "user_id", 
                  "session_id", 
                  "last_request", 
                  "last_response", 
                  "total_tokens",
                  "prompt_tokens",
                  "completion_tokens",
                  "est_token_cost",
                  ]} 
                valhandler={ChatNavLink}
                  />
              <BlankPlaceHolder count={160} />
            </CardBody>
            <CardFooter className="pt-0">

            </CardFooter>
          </Card>
        )}
        {tabIndex === 1 && (
          <Card className="pt-6  w-auto">
            <CardBody>
              <KeyValTable record={reqJson} 
                fields={["role", "content"]}
                keyhandler={RoleIcon}
              />
            </CardBody>
            <CardFooter className="pt-0">
              <Button variant="gradient" className="flex items-center gap-3" onClick={() => handleRequestDownload()} >
                <ArrowDownOnSquareIcon strokeWidth={2} className="h-5 w-5" /> Download Raw Json File
              </Button>
            </CardFooter>
          </Card>
        )}
        {tabIndex === 2 && (
          <>
          <Card className="pt-6  w-auto">
            <CardBody>
              <Typography variant="h5" color="blue-gray" className="mb-2">
               Response from OpenAI 
              </Typography>
              <Typography>
                {rspJson.choices[0].text}
              </Typography>
              <JSONTree data={rspJson} />
            {/* <JsonDebug label="Response" data={rspJson} /> */}
            </CardBody>
            <CardFooter className="pt-0">
              <Button variant="gradient" className="flex items-center gap-3" onClick={() => handleRespDownload()} >
                <ArrowDownOnSquareIcon strokeWidth={2} className="h-5 w-5" /> Download Raw Json File
              </Button>
            </CardFooter>
          </Card>
          </>
        )}
        {tabIndex === 3 && (
          <Card className="pt-6  w-auto">
            <CardBody>
              {/* <KeyValTable record={chatrecord}
                fields={[
                  "pii_ssn", 
                  "pii_email", 
                  "pii_address", 
                  ]} /> */}
                
              <table className="w-96 full min-w-max table-auto text-left ml-40">
                <tbody>
                  <tr>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      {/* <FingerPrintIcon  strokeWidth={2} className="h-4 w-4" />   */}
                      Social Security Number 
                    </td>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      <Checkbox  color="blue" disabled  checked={chatrecord.pii_ssn} />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      Email
                    </td>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      <Checkbox color="blue" disabled   checked={chatrecord.pii_email} />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      Address
                    </td>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      <Checkbox color="blue" disabled   checked={chatrecord.pii_address} />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      Phone
                    </td>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      <Checkbox color="blue" disabled   checked={chatrecord.pii_phone} />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      Username
                    </td>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      <Checkbox color="blue" disabled   checked={chatrecord.pii_username} />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      Password
                    </td>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      <Checkbox color="blue" disabled   checked={chatrecord.pii_password} />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      Sensitive Term
                    </td>
                    <td className="border-b border-blue-gray-200 py-3 px-5 text-left">
                      <Checkbox color="blue" disabled   checked={chatrecord.pii_sensitive_term} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
            <CardFooter className="pt-0">
                
            </CardFooter>
          </Card>
        )}
        {tabIndex === 4 && (
          <Card className="pt-6  w-auto">
            <CardBody>
            </CardBody>
            <CardFooter className="pt-0">

            </CardFooter>
          </Card>
        )}
        {tabIndex === 5 && (
          <Card className="pt-6  w-auto">
            <CardBody>
              <CreateCaseForm chatId={chatId} sessionId={sessionId} />
            </CardBody>
            <CardFooter className="pt-0">

            </CardFooter>
          </Card>
        )}
    </Box>
    <Box>
      <NavLink to={back_url} 
       className={ ({isActive}) => [
          "group flex items-center px-2 py-2 text-base font-medium rounded-md",
          isActive ? "bg-blue-500 text-white" : null,
        ].filter(Boolean).join(" ")}
      >Back</NavLink>
    </Box>
    </Box>
  )

};

export default ChatDetailComponent;