
import React from "react";
import { NavLink } from "react-router-dom";

interface SettingSectionProps {
    img: string;
    title: string;
    description: string;
    link: string;
}

// The section data can be eventually loaded from db for dynamic settings building blocks
const SectionsData = [
    {
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/240px-ChatGPT_logo.svg.png",
        title: "ChatGPT",
        description: "Model pricing, default params, enpoints, etc.",
        link: "/dashboard/settings/chatgpt",
    },
    {
        img: "https://images.unsplash.com/photo-1539404234905-48cf3d73aec5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
        // img: "https://images.unsplash.com/photo-1469719847081-4757697d117a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
        // img: "https://images.unsplash.com/photo-1583775598026-24bd150b540e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1501&q=80",
        title: "Sensitive Terms",
        description: "Customize sensitive terms for your business",
        link: "/dashboard/settings/terms",
    },
    {
        img: "https://images.unsplash.com/photo-1626460561069-c86d94488bbd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80",
        // img: "https://images.unsplash.com/photo-1541480601022-2308c0f02487?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
        // img: "https://media.istockphoto.com/id/1334694973/photo/3d-render-icon-of-glossy-yellow-notification-bell-with-one-new-message-isolated-on-pink.webp?s=2048x2048&w=is&k=20&c=WDSQ69eLpUnzQwGinvT4ZFj7V5hc7C1CnXWVTNyw0qw=",
        title: "Event/Alert",
        description: "Enable/disable certain alerts & configure notification channels",
        link: "/dashboard/settings/event",
    },
    {
        img: "https://images.unsplash.com/photo-1666875753105-c63a6f3bdc86?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
        // img: "https://images.unsplash.com/photo-1511447333015-45b65e60f6d5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1555&q=80",
        title: "Report",
        description: "Offline report delivery & scheduling",
        link: "/dashboard/settings/report",
    },
    {
        img: "https://upload.wikimedia.org/wikipedia/commons/1/14/Anthropic.png",
        title: "Claude2",
        description: "Anthropic Claude 2 API configuration.",
        link: "/dashboard/settings/claude",
    },
    {
        img: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Google_Bard_logo.svg",
        // img: "https://www.gstatic.com/lamda/images/sparkle_resting_v2_1ff6f6a71f2d298b1a31.gif",
        title: "Bard",
        description: "Google Bard API settings.",
        link: "/dashboard/settings/bard",
    },
    {
        img: "https://images.unsplash.com/photo-1655720031554-a929595ffad7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80",
        // img: "https://images.unsplash.com/photo-1655720033654-a4239dd42d10?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80",
        // img: "https://images.unsplash.com/photo-1519493442754-8eb0a6cec050?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=765&q=80",
        // img: "https://images.unsplash.com/photo-1491441555545-d6b8e24ffa33?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
        title: "A/B",
        description: "Transparent A/B testing setup.",
        link: "/dashboard/settings/abtest",
    },
    {
        img: "https://images.unsplash.com/flagged/photo-1561023367-50a6e054d890?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
        // img: "https://images.unsplash.com/photo-1641495623770-a5dc919f38d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80",
        title: "Key Management",
        description: "Security key management.",
        link: "/dashboard/settings/keys",
    },
];

export const SettingSection: React.FC<SettingSectionProps> = ( {img, title, description, link} ) => {
    return (
        <>
        <article className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 ml-2">
        <NavLink to={link}>
        <div className="relative flex items-end overflow-hidden rounded-xl w-56 h-56">
            <img src={img} alt="Hotel Photo" className="object-cover object-center w-full h-full" />
        </div>

            <div className="mt-1 p-2">
            <div className="mt-3 flex items-end justify-between">
                <p className="text-lg font-bold text-blue-500">{title}</p>
            </div>
            <h2 className="text-slate-700">{description}</h2>
            </div>
        </NavLink>
        </article>
        </>
    )
}


export const SettingList = () => {


    return (<>

<section className="py-10 bg-gray-100">
  <div className="mx-auto grid max-w-6xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    {SectionsData.map((section) => (
        <SettingSection img={section.img} title={section.title} description={section.description} link={section.link} />
    ))}
  </div>
</section>

    </>);
}







