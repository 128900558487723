import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Tooltip,
  Progress,
} from "@material-tailwind/react";
import {
  ClockIcon,
  CheckIcon,
  EllipsisVerticalIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";
import  StatCard  from "../widgets/StatCard";
import  StatChart from "../widgets/StatChart";

import {
  statisticsCardsData,
  statisticsChartsData,
  projectsTableData,
  ordersOverviewData,
} from "../data";

import {
  BanknotesIcon,
  UserPlusIcon,
  UserIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";

// import { useGetMetricsAdminMetricsGetQuery } from "../../apis/adminEndpoints";

import { MetricsStatChart } from "../widgets/MetricsStatChart";
import { MetricsStatCard } from "../widgets/MetricsStatCard";
import { TrendingWordList } from "../widgets/TrendingWordList";

export function Home() {

  // const { data, error, isLoading } = useGetMetricsAdminMetricsGetQuery({
  //   startTime: '2023-01-01', 
  //   endTime:'2023-06-06', 
  //   dimension:'hour', 
  //   metrics:['request']});
  
  // if(isLoading) return <div>Loading...</div>;
  // if(error) return <div>Error: {error.toString()}</div>;

  // console.log(">>>> data: " , data);

  return (
    <div className="mt-12">
      <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
        <MetricsStatCard
          metrics={["user"]}

          title="Distinct Users (past 30 days)"
          icon={<UserIcon className="w-6 h-6 text-white"/>}
          footer={
            <Typography className="font-normal text-blue-gray-600">
              <strong className="text-green-500">3%</strong>
              &nbsp;than last month
            </Typography>
          }
        />

        <MetricsStatCard
          metrics={["total_tokens"]}
          color='pink'
          title="Total tokens (past 30 days)"
          icon={<BanknotesIcon className="w-6 h-6 text-white"/>}
          footer={
            <Typography className="font-normal text-blue-gray-600">
              <strong className="text-green-500">5%</strong>
              &nbsp;than last month
            </Typography>
          }
        />

        <MetricsStatCard
          metrics={["session"]}
          color='green'
          title="Total session (past 30 days)"
          icon={<BanknotesIcon className="w-6 h-6 text-white"/>}
          footer={
            <Typography className="font-normal text-blue-gray-600">
              <strong className="text-green-500">1%</strong>
              &nbsp;than last month
            </Typography>
          }
        />

        <MetricsStatCard
          metrics={["request"]}
          color='orange'
          title="Total request (past 30 days)"
          icon={<BanknotesIcon className="w-6 h-6 text-white"/>}
          footer={
            <Typography className="font-normal text-blue-gray-600">
              <strong className="text-red-500">-1%</strong>
              &nbsp;than last month
            </Typography>
          }
        />

      </div>
      <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-3">

        <MetricsStatChart
            chartType="bar"
            title="User/Day" 
            description="Active user per day"
            dimension="date" 
            metrics={["user"]} 
            color="blue"

            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-blue-gray-600"
              >
                <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                &nbsp; For the last 30 days.
              </Typography>
            }
        />
        <MetricsStatChart
            chartType="line"
            title="Request" 
            description="Request Per Day"
            dimension="date" 
            metrics={["request"]} 
            color="pink"

            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-blue-gray-600"
              >
                <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                &nbsp; For the last 30 days.
              </Typography>
            }
        />

        <MetricsStatChart
            chartType="line"
            title="Session" 
            description="Sessions Per Day"
            dimension="date" 
            metrics={["session"]} 
            color="green"

            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-blue-gray-600"
              >
                <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                &nbsp; For the last 30 days.
              </Typography>
            }
        />

      </div>
      <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3 mt-16">
        <TrendingWordList className="overflow-y-scroll max-h-min" />

        <div>

        <MetricsStatChart
            chartType="pie"
            title="Private Info" 
            description="Private, personal or sensitive information."
            metrics={[
              "pii_email", 
              "pii_ssn", 
              "pii_credit_card", 
              "pii_username", 
              "pii_password",
            ]} 
            color="gray"

            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-blue-gray-600"
              >
                <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                &nbsp; For the last 30 days.
              </Typography>
            }
        />

        <MetricsStatChart
            chartType="pie"
            title="Topics of Interest" 
            description="Trending topics in conversations."
            metrics={[
              "request", 
            ]}
            dimension="request_topic"
            pieChartDataFields={["request_topic", "request_count"]}
            color="gray"

            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-blue-gray-600"
              >
                <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                &nbsp; For the last 30 days.
              </Typography>
            }
        />
        <MetricsStatChart
            chartType="pie"
            title="Request Sentiment" 
            description="Request Sentiment"
            metrics={[
              "request", 
            ]}
            dimension="request_sentiment"
            pieChartDataFields={["request_sentiment", "request_count"]}
            color="gray"

            footer={
              <Typography
                variant="small"
                className="flex items-center font-normal text-blue-gray-600"
              >
                <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                &nbsp; For the last 30 days.
              </Typography>
            }
        />
        </div>

      </div>
    </div>
  );
}

export default Home;
