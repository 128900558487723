import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Tooltip,
  Progress,
} from "@material-tailwind/react";

import {
  ClockIcon,
  CheckIcon,
  EllipsisVerticalIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";

import {useWordCountsQuery, useTrendingWordCloud } from "../../apis/endpoints/admin/wordcloudService";

interface WordCloudInfo {
    text: string;
    value: number;
}
interface TreandingWordListProps { 
    className?: string;
}

export const TrendingWordList: React.FC<TreandingWordListProps> = ({className=undefined}) => {
    
    // const {data, error, isLoading} = useTrendingWordCloud();
    const {data, error, isLoading} = useWordCountsQuery({limit:30, source:'request'});

    console.log(">>>> trending word data: " , data, " error: ", error, " isLoading: ", isLoading);

    if(isLoading) return <div>Loading...</div>;
    if(error) return <div>Error: {error.toString()}</div>;

    // sample [{"text": "DALL-E", value: 9}, .....]
    const items = data?.json as WordCloudInfo[];

    const total = items.reduce((acc, current) => acc + current.value, 0);

    const newData = items.map((item) => ({
    ...item,
    percentage: (item.value / total) * 100,
    }));

    // ---

    return ( 
        <Card className={`overflow-hidden xl:col-span-2 ${className ?? ""}`}>
        <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 flex items-center justify-between p-6"
        >
        <div>
            <Typography variant="h6" color="blue-gray" className="mb-1">
            Trending Words
            </Typography>
            <Typography
            variant="small"
            className="flex items-center gap-1 font-normal text-blue-gray-600"
            >
            <CheckIcon strokeWidth={3} className="h-4 w-4 text-blue-500" />
            <strong>top 30 </strong> this month, % calculation only based on top items
            </Typography>
        </div>
        <Menu placement="left-start">
            <MenuHandler>
            <IconButton size="sm" variant="text" color="blue-gray">
                <EllipsisVerticalIcon
                strokeWidth={3}
                fill="currenColor"
                className="h-6 w-6"
                />
            </IconButton>
            </MenuHandler>
            <MenuList>
            <MenuItem>Action</MenuItem>
            <MenuItem>Another Action</MenuItem>
            <MenuItem>Something else here</MenuItem>
            </MenuList>
        </Menu>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        <table className="w-full min-w-[640px] table-auto">
            <thead>
            <tr>
                {["text", "value", "percentage"].map(
                (el) => (
                    <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-6 text-left"
                    >
                    <Typography
                        variant="small"
                        className="text-[11px] font-medium uppercase text-blue-gray-400"
                    >
                        {el}
                    </Typography>
                    </th>
                )
                )}
            </tr>
            </thead>
            <tbody>
            {newData.map(
                ({ text, value, percentage }, key) => {
                const className = `py-3 px-5 ${
                    key === items.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`;

                return (
                    <tr key={text}>
                    <td className={className}>
                        <div className="flex items-center gap-4">
                        {/* <Avatar src={img} alt={name} size="sm" /> */}
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold"
                        >
                            {text}
                        </Typography>
                        </div>
                    </td>
                    <td className={className}>
                        <Typography
                        variant="small"
                        className="text-xs font-medium text-blue-gray-600"
                        >
                        {value}
                        </Typography>
                    </td>
                    <td className={className}>
                        <div className="w-10/12">
                        <Typography
                            variant="small"
                            className="mb-1 block text-xs font-medium text-blue-gray-600"
                        >
                            {percentage}%
                        </Typography>
                        <Progress
                            value={percentage}
                            variant="gradient"
                            color={percentage > 5 ? "green" : "blue"}
                            className="h-1"
                        />
                        </div>
                    </td>
                    </tr>
                );
                }
            )}
            </tbody>
        </table>
        </CardBody>
        </Card>
   );
}

export default TrendingWordList;