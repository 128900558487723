import React from "react";
import {
  Avatar,
  Card,
  CardHeader,
  CardBody,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  TabsBody,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import { NavLink, useParams } from "react-router-dom";
import { SessionCard } from "../sessions/SessionCard";
import { CasesListCard } from "../cases/CasesListCard";
import { BookOpenIcon, BriefcaseIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";

export const DepartmentDetail = () => {
    
  const { id } = useParams();

    return (
        <>
        <div className="pt-6">

        <Tabs value="chat table" className="w-full">
              <TabsHeader
                className=" w-80"
                indicatorProps={{
                  className: "bg-blue-500/10 shadow-none text-blue-500",
                }}
              >
                  <Tab key={"chat table"} value={"chat table"}>
                    <div className="flex items-center gap-2">
                      <ChatBubbleLeftRightIcon className="h-5 w-5" />
                      Sessions 
                    </div>
                  </Tab>
                  <Tab key={"conversation"} value={"conversation"}>
                    <div className="flex items-center gap-2">
                      <BriefcaseIcon className="h-5 w-5" />
                      Cases/Tickets 
                    </div>
                  </Tab>
                  <Tab key={"system prompt"} value={"system prompt"}>
                    <div className="flex items-center gap-2 w-40">
                      <BookOpenIcon className="h-5 w-5" />
                      System Prompts 
                    </div>
                  </Tab>
              </TabsHeader>

              <TabsBody>
                  <TabPanel key={"chat table"} value={"chat table"}>
                    <Typography className="pb-4">
                        Chat Sessions for the past 90 days
                    </Typography>
                    <SessionCard  departmentId={id} />
                  </TabPanel>
                  <TabPanel key={"conversation"} value={"conversation"}>
                    <Typography className="pb-0 mt-12">
                        Open Investigation Cases 
                    </Typography>
                    <CasesListCard departmentId={parseInt(id!)} showHeader={false} />
                  </TabPanel>
                  <TabPanel key={"system prompt"} value={"system prompt"}>
                  </TabPanel>
              </TabsBody>
        </Tabs>
        </div>
        </>
    );
}
