/**
 * Download report through API server, and return blob data. Cannot directly downloa via link,
 * as it needs JWT token passing.
 */
import { useQuery } from "react-query";
import { fetchBlob } from "../../../../auth/DataProvider";
import { daysAgo, roundToMinute } from "../../../../utils/datetime";
import { round } from "lodash";

export const useFetchReport = () => {
  const fetchPdf = async (name: string) => {
    const response = await fetchBlob(
      `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/report?name=${name}`
    );
    const text = response.body;
    // need to manually convert it.
    const blob = new Blob([text], { type: "application/pdf" });
    return blob;
  };

  return fetchPdf;
};
