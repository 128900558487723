// landing page of amdin after login, now just simply redirect to new dashboard layout
import { useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title, useRedirect } from 'react-admin';

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

import { Dashboard } from "../dashboard";

import AltDashboard from '../altlayout/DashboardLayout';

export default () => { 

    const redirect = useRedirect();

    const gotoNewDashboard = () => {
        redirect('/dashboard/home');
    };

    // automatically redirect after login
    useEffect(() => {
        gotoNewDashboard();
    }, []);
    
    return (
        <div>loading...</div>
    );
};