// Slice definition for the alter layout which is currently tailwind css based.
// It may be cubersome to stack layers of context and then all them via 
// useContext() inside the individual components, it can be a use case for redux.

// Since the original main react-admin v4.x is already decoupled from redux, it 
// should be safe to name top slice state as 'layout'.

import { createAsyncThunk, createSlice, isAction, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { stat } from 'fs';


// NOTE: too narrow if we define the sidenavColor to the button color type below
//       for now just use string. 
// import type {color} from "@material-tailwind/react/types/components/button";
export interface LayoutState {
    /** The theme of whole layout, reserved for future use, no theme applied yet
     *  at this point. */
    theme: "light" | "dark";
    /** The color of the sidenav. */
    sidenavColor: string;
    /** The type of the sidenav, default should be 'dark'. */
    sidenavType: string; 
    /** Whether the sidenav is open or not. */
    openSidenav: boolean;
    /** Whether the navbar is fixed or not. */ 
    fixedNavbar: boolean;
    /** Whether the configurator is open or not. */
    openConfigurator: boolean;
    /** Whether the navbar is transparent or not. */
    transparentNavbar:boolean;
}

const initialState: LayoutState = {
    theme: "light",

    sidenavColor: "blue",
    sidenavType: "dark",
    openSidenav: false,
    fixedNavbar: false,
    openConfigurator: false,
    transparentNavbar: false,
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        updateTheme: (state, action) => {
            state.theme = action.payload;
        },
        setOpenConfigurator: (state, action) => {
            //TODO: implement 
            console.log("setopenconfigurator: ", action.payload)
        },
        setOpenSidenav: (state, action) => {
            //TODO: implement
            console.log("setOpenSidenav: ", action.payload)
        },

        setSidenavColor : (state, action) => {
            //TODO: implement
            console.log("setSidenavColor: ", action.payload)
        },
        setSidenavType : (state, action) => {
            //TODO: implement
            console.log("setSidenavType: ", action.payload)
        },
        setFixedNavbar : (state, action) => {
            //TODO: implement
            console.log("setFixedNavbar: ", action.payload)
        },
    }
});

// actions creators
export const { 
    updateTheme, 
    setOpenConfigurator,
    setOpenSidenav,
    setSidenavColor,
    setSidenavType,
    setFixedNavbar,
} = layoutSlice.actions;

// selector 
export const selectLayoutState = (state: RootState) => state.layout;

export default layoutSlice.reducer;