import React from "react";
import {
  Avatar,
  Card,
  CardHeader,
  CardBody,
  Input,
  Typography,
} from "@material-tailwind/react";
import {SessionCard} from "../widgets/sessions/SessionCard" 


export const SessionView = () => {

    //TODO: add date picker, etc.

    return ( <>
    <div className="my-5">
        <div className="flex  gap-6">
        <Input size="md" label="Start Date" type="date" />
        <Input size="md" label="End Date" type="date" />
        </div>
        <div className="flex flex-col w-72 items-end gap-6">
        </div>
    </div>

    <SessionCard />
    </>
    )

}
