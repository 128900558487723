import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Switch,
  Typography,
} from "@material-tailwind/react";


export const KeyMgmtSetting = () => {

    return (<>
    
    <div className="gird-cols-1 mb-12 grid gap-12 px-4  p-16 shadow-lg rounded-lg">
        <div className="ml-8">

            <Typography variant="h6" color="blue-gray" className="mb-3">
                Key Management Settings
            </Typography>

            <div className="mb-8 ">
                <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
                    AWS KMS Setting
                </Typography>

                <Input disabled={true} labelProps={{shrink:false, visuallyHidden: false,}} label="Key ARN" value="arn:aws:kms:us-east-1:123456789012:key/12345678-9012-3456-7890-123456789012" />
            </div>

        </div>

    <NavLink to="/dashboard/settings" >
        <Button variant="gradient" className="mt-1 ml-6" >
        Back 
        </Button>
    </NavLink>
    </div>
    </>);
}