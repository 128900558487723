// for chatgpt v4 pricing configuration, which is quite different 
import { 
    Edit, 
    NumberInput, 
    SimpleForm, 
    TabbedForm,
    TextInput, 
    TextField,
    Toolbar,
    useRecordContext,
    SaveButton,
    useEditContext,
} from 'react-admin';
import SaveOnlyToolbar from './SaveOnlyToolbar';
import { JsonDebug } from '../../utils/JsonDebug';

const InternalGpt40edit = () => { 

    // NOTE: it must be put inside edit context such as <Edit>, or <EditBase>
    //       otherwise, it will always be null.
    // Sample result:
    //{
    //     "id": "chatgpt.v4",
    //     "data": {
    //         "model": "gpt-4",
    //         "pricing": {
    //             "context_32k": {
    //                 "unit_price": 0.06,
    //                 "token": 1000
    //             },
    //             "context_8k": {
    //                 "unit_price": 0.03,
    //                 "token": 1000
    //             }
    //         }
    //     }
    // }
    var { record, isLoading} = useEditContext()
    if(isLoading) { return null;}

    console.log(">>>>v40 record", record)
    return (
        <SimpleForm toolbar={<SaveOnlyToolbar />}>
                <div>8K context prcing</div>
                <NumberInput source="data.pricing.context_8k.unit_price" label="$ per 1k token" step={0.001} />
                <div>32K context prcing</div>
                <NumberInput source="data.pricing.context_32k.unit_price" label="$ per 1k token" step={0.001} />
                <JsonDebug label="record" data={record} />
        </SimpleForm>
    )
}

const Gpt40Edit = () => {
    return (<InternalGpt40edit />)
}

export default Gpt40Edit;