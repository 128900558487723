import { useAuthenticated } from "react-admin";
import _ from "lodash";
// import path from "path";
import { Routes, Route, Outlet } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { IconButton } from "@material-tailwind/react";
// import {
//   Sidenav,
//   DashboardNavbar,
//   Configurator,
//   Footer,
// } from "@/widgets/layout";

import Sidenav from "./Sidenav";
import DashboardNavbar from "./DashboardNavbar";
import Configurator from "./Configurator";
import Footer from "./Footer";

// import routes from "@/routes";
import navroutes from "./NavRoutes";

// import { useMaterialTailwindController, setOpenConfigurator } from "@/context";

import { useSelector, useDispatch } from "react-redux";
// action creators
import { 
    setOpenConfigurator,
    setSidenavColor,
    setSidenavType,
    setFixedNavbar,
} from "./layoutSlice";

// selectors
import { selectLayoutState } from "./layoutSlice";


const pathPrefix = (prefix : string, relativePath: string) => {
  if (!prefix || prefix.trim().length === 0) {
    return relativePath;
  }

  // clean prefix without trailing '/'
  const cleanPrefix = prefix.endsWith("/") ? prefix.slice(0, -1) : prefix;
  // clean path without leading '/'
  const cleanPath = relativePath.startsWith("/") ? relativePath.slice(1) : relativePath;

  return `${cleanPrefix}/${cleanPath}`;
}
  

export function Dashboard() {
  // need to enforce authentication, by default react-admin will NOT cover this for custom pages.
  useAuthenticated();

//   const [controller, dispatch] = useMaterialTailwindController();
//   const { sidenavType } = controller;

    const { sidenavType } = useSelector(selectLayoutState); 
    const dispatch = useDispatch();

    const _applyPrefix = _.curry(pathPrefix)("/dashboard"); 

  return (
    <div className="min-h-screen bg-blue-gray-50/50">
      <Sidenav
        routes={navroutes}
        brandImg={
          sidenavType === "dark" ? "/img/logo.png" : "/img/logo-dark.png"
        }
      />
      <div className="p-4 xl:ml-80">
        <DashboardNavbar />
        <Configurator />
        <IconButton
          size="lg"
          color="white"
          className="fixed bottom-8 right-8 z-40 rounded-full shadow-blue-gray-900/10"
          ripple={false}
        //   onClick={() => setOpenConfigurator(dispatch, true)}
          onClick={() => dispatch(setOpenConfigurator(true))}
        >
          <Cog6ToothIcon className="h-5 w-5" />
        </IconButton>

        {/* take advantage of react-router-dom v6.x */}
        <Outlet />
        {/* <Routes>
          {navroutes.map(
            ({ layout, pages }) =>
              layout === "dashboard" &&
              pages.map(({ path, element }) =>{ 
                console.log( "--path: ", path, " after apply prefix:" , _applyPrefix(path), " element: ", element) ;
                return (
                // we use v6.x now, no more exact path
                // <Route exact path={path} element={element} />
                <Route path={_applyPrefix(path)} element={element} />
              )})
          )}
        </Routes> */}

        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  );
}


export default Dashboard;
