/**
 * Endpoints for word cloud, currently has two APIs, one for mock trending word cloud (will 
 * hook up the service later) that return react-wordcloud specific format, and another one
 * is for query the real chat_word_count table.
 */

import {useQuery} from "react-query";
import {fetchJson} from "../../../../auth/DataProvider" 
import {daysAgo, roundToMinute } from "../../../../utils/datetime";


interface WordCloudQueryProps {
}


export const useTrendingWordCloud = () => {

    // for the key, we change it maybe 5 min, let's just play the trick without passing
    // cache poilicy settings for now
    const roundedTime = roundToMinute(new Date(), 5);
    return useQuery(
        // TODO: cached key needs to be carefully considered, if the start/end time is constantly changing 
        //       there will be too many calls to API server. However, we also wants to refresh the data on 
        //       time, so may play with cache policy later.
        // ['metrics', startTime, endTime, dimension, metrics],
        
        ['trending',  ],
        // the fetcher function
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/feed/trending`,
            { method: 'GET' }
        )
    )
}


interface WordCountQueryProps {
    startTime?: string;
    endTime?: string;
    limit?: number;
    source?: string;
}
export const useWordCountsQuery = ({
    startTime = daysAgo(30).toISOString(), 
    endTime = daysAgo(0).toISOString(),
    limit,
    source,
} : WordCountQueryProps) => {

    const _start = roundToMinute( new Date(startTime));
    const _end = roundToMinute( new Date(startTime));
    return useQuery(
        // TODO: cached key needs to be carefully considered, if the start/end time is constantly changing 
        //       there will be too many calls to API server. However, we also wants to refresh the data on 
        //       time, so may play with cache policy later.
        // ['metrics', startTime, endTime, dimension, metrics],
        
        ['word_counts', _start, _end, limit, source ],
        // the fetcher function
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/word_counts?start_date=${startTime}&end_date=${endTime}&limit=${limit}&source=${source}`,
            { method: 'GET' }
        )
    )
}

