import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface ChatsState {
  value: number;
  lastUpdate: string; // make it serializable, somply JSON.stringify(new Date())
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ChatsState = {
    value: 0,
    lastUpdate: JSON.stringify(new Date()),
    status: 'idle'
}

// createSlice will automatically handle mutable code to make it immutable, 
// which is why we can use the "mutating" syntax here. Save lots of time.
// Do NOT use the "mutating" syntax ouside the scope.
export const chatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        refresh: (state) => {
            state.lastUpdate = JSON.stringify(new Date());
        },
    }
});

// actions creators
export const { refresh } = chatsSlice.actions;

// selector 
export const selectChats = (state: RootState) => state.chats.value;

export const selectLastUpdate = (state: RootState) => state.chats.lastUpdate;

export default chatsSlice.reducer;