import { useAppSelector, useAppDispatch } from '../../app/hooks'; 
import { selectLastUpdate, refresh } from './chatRecordSlice';
import { 
    Datagrid, 
    DateField, 
    List,
    ListBase,
    NumberField,
    ReferenceField,
    TextField,
    UrlField,
    useRecordContext,
    useResourceDefinition,
} from 'react-admin';

import {Box, Drawer, useMediaQuery, Theme} from '@mui/material';
import TextInput from '@mui/material/TextField';

import Aside from './Aside';
import EmptyChatList from './Empty';
import BlankPlaceHolder from '../../utils/BlankPlaceHolder';

import ChatDetailComponent from './ChatDetail';

// import {Route} from 'react-router';
import { Routes, Route, useMatch, useParams } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router-dom';

interface SourceAttr {
    source: string
}

const truncate_size = 150;

const TruncateTextField = ({source}: SourceAttr) => {
    const record = useRecordContext();
    if (!record) return null; 
    //TODO: need to test blank string and null
    // No need to worry about overflow, as it is handled, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/substring
    return (<div>
        {record[source].substring(0, truncate_size)}...<b>(total {record[source].length})</b>
    </div>)
};



export const ChatrecordList = () => { 
    // need to to know media that is rendering to
    const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

    console.log("about to get the last update time")
    const _updateTime = useAppSelector(selectLastUpdate);
    
    // We will use it outside of ResourceContext as we won't mount it under <Resource > soon
    // as result, let's be very explicit on resource usage.
    // For ultimate control, we may want to go raw data fetching or useListController({resource: "chatrecord"}) later on.
    // We have full control on the source, now just go for simple prop set.
    // Based on react-admin source code (ResourceContextProvider, to be specific, which is used by ListBase, which is used 
    // by List), the resource is optional, and can be undefined (cannot be null, because of its type definition). 
    const { hasList } = useResourceDefinition();
    const resource = hasList ?  undefined : "chatrecord" ;

    console.log("and the time is: " + _updateTime);

    return  (
    <>

            <List aside={<Aside /> }
                resource={resource}
                sx={{ minHeight: '80vh' }}
                perPage={25}
                >
                <Datagrid 
                        //rowClick="edit" 
                        // rowClick={(id, basePath, record) => `/chatrecord/${id}/detail`}
                        rowClick={(id, basePath, record) => `/dashboard/chatdetail/${id}`}
                        empty={<EmptyChatList />}  
                        sx={{minHeight: '80vh'}} >
                    <TextField source="id" />
                    <DateField source="creation_time" />

                    <NumberField source="total_tokens" />
                    <NumberField source="prompt_tokens" />
                    <NumberField source="completion_tokens" />

                    <TextField source="user_id" />
                    <TextField source="session_id" />

                    {/* <ReferenceField source="user_id" reference="users" />
        <ReferenceField source="session_id" reference="sessions" /> */}

                    {/* <TextField source="request" /> */}
                    {/* <TruncateTextField source="request" /> */}
                    {/* <TextField source="response" /> */}
                    {/* <TruncateTextField source="response" /> */}
                </Datagrid>

                <BlankPlaceHolder count={200} />
            </List>
            
            <div>
            {/* <Routes>
                <Route path="/chatrecord/:id" element={<ChatDetailComponent/>}>
                </Route>
            </Routes> */}
            </div>
    </>
    )
};