import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Switch,
  Typography,
} from "@material-tailwind/react";

export const reportSwitchData = [
    {
      title: "PII & Sensitive Terms",
      options: [
        {
          checked: true,
          label: "Enable SSN Detection",
        },
        {
          checked: true,
          label: "Enable credit card Detection",
        },
        {
          checked: true,
          label: "Enable user name detection",
        },
        {
          checked: true,
          label: "Enable password detection",
        },
        {
            checked: false,
            label: "Enable address detection",
        },
        {
            checked: true,
            label: "Enable sensitive term detection",
        }
      ],
    },

  ];

export const SensitiveTermsSetting = () => {

    return (<>
    
    <div className="gird-cols-1 mb-12 grid gap-12 px-4  p-16 shadow-lg rounded-lg">
        <div className="ml-8">

            <Typography variant="h6" color="blue-gray" className="mb-3">
                Sensitive Terms Settings
            </Typography>

            <div className="mb-8 ">
                <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
                Notification
                </Typography>
              <div className="flex flex-col gap-12">
                {reportSwitchData.map(({ title, options }) => (
                  <div key={title}>
                    <Typography className="mb-4 block text-xs font-semibold uppercase text-blue-gray-500">
                      {title}
                    </Typography>
                    <div className="flex flex-col gap-6">
                      {options.map(({ checked, label }) => (
                        <Switch
                          key={label}
                          id={label}
                          label={label}
                          defaultChecked={checked}
                          labelProps={{
                            className: "text-sm font-normal text-blue-gray-500",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ))}

            </div>
          </div>
        </div>

    <NavLink to="/dashboard/settings" >
        <Button variant="gradient" className="mt-1 ml-6" >
        Back 
        </Button>
    </NavLink>
    </div>
    </>);
}