import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Chip,
  Tooltip,
  Progress,
} from "@material-tailwind/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

import EventCard from "../widgets/EventCard";

const Event = () => {
  return (
    <>
      <EventCard title="Important Events & Alerts" />
    </>
  );
};

export default Event;
