import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';

// General 404 landing component for anything that is not found properly. 
// TODO: 
//   - may read and display addtional dynamic content if needed.
//   - better looking with proper css and images. 
export default () => (
    <Card>
        <Title title="Not Found" />
        <CardContent>
            <h1>404: Page not found</h1>
        </CardContent>
    </Card>
);