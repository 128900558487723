/**
 * This is a blank layout with nothing inside. It is used to avoid the "flash" of older 
 * default layout, after we switch to our new, hand-crafted layout, which has will be
 * parted away from the react-admin. We want user to see a blank page for a fraction of
 * second, and then the new layout.
 */


import { Layout, LayoutProps, AppBar, Menu } from 'react-admin';

const emptyAppBar = () => <div></div>;
const emptyMenu = () => <div></div>;

//NOTE: can only use 'undefined' here for appBar and menu, TS won't allow 'null', due to 
//      type checking. However, it won't work as it just fallback to default AppBar and 
//      menu.  And hence we need to pass some real components but keep them empty. Now 
//      there's a quick "...loading" in blank page, and then our real layout shows up.
const CustomLayout = (props: LayoutProps) => (
    <Layout 
        {...props} 
        appBar={emptyAppBar} 
        menu={emptyMenu} 
    />
);

export default CustomLayout;