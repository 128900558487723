import {Box} from '@mui/material';

/**
 * Props to define total number of blank string '&nbsp;' that needs to repeat.
 */
interface BlankPlaceHolderProps {
  count: number;
}

/**
 * Simple div placeholder with blank strings, for lazy developers. Due to the 
 * css flex box layout nature, sometimes the UI looks too small or too narrow
 * during development, while you just prototyping, and you don't want to spend
 * time for css style tweaking(not even the sx props), then this is handy tool
 * to spam '&nbsp;' for you, LOL. Not recommended to use after certain dev stage. 
 *
 * @param {Props} props - The component props.
 * @param {number} props.count - The count value.
 * @returns {JSX.Element} - The component JSX.Element.
 */
const BlankPlaceHolder:React.FC<BlankPlaceHolderProps> 
    = ({ count = 160 }): JSX.Element => {

    return (
    <Box sx={{width:'100%'}}>
        {String.fromCharCode(160).repeat(count)}
    </Box>

    )
};

export default BlankPlaceHolder;