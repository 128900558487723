import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Typography,
} from "@material-tailwind/react";
import { useSessionDetailQuery } from "../../../apis/endpoints/admin/sessionServices";


interface CardListCardProps {
    chatList: Array<{ [key: string]: any }>;
}

export const ChatListCard: React.FC<CardListCardProps> = ({chatList}) => {
    return(<>
    
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {["Chat ID", "Time", "Request", "Response"].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {chatList.map((row, index) => {
                const className = `py-3 px-5 ${
                  index === chatList.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`;

                return (
                  // for each row, based on odd or even index, set bg color
                  <tr
                    key={index}
                    className={index % 2 == 0 ? "bg-gray-50" : "bg-white"}
                  >
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        <NavLink
                          to={`/dashboard/chatdetail/${row!.id}`}
                          className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium"
                        >
                          {row.id}
                        </NavLink>
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.creation_time}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.last_request}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.last_response}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
    
    </>)
}