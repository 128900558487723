import { getToken, setToken, removeToken } from './InMemJwt'

// const JWT_LOGIN_URL = 'http://localhost:8000/login'
const JWT_LOGIN_URL = import.meta.env.VITE_REACT_APP_JWT_LOGIN_URL

// destructive assignment is messy with ts7031 error on, may change the compiler setting later
interface LoginAuthForm {
    username: string, 
    password: string
}

//TODO: it currently tightly associated with existing simple demo JWT token schema, such as "access_token"
//      property, etc. For real world integration, it needs to be rewritten, or adapt a more generic provider
//      way. In such case, we can simply pick registered auth providers based on configurations, for instance,
//      for demo, staging and different clients, they may have completely different authProvider. 
export const authProvider = {
    login: ({ username, password }: LoginAuthForm) => {
        // not t a json endpoint, but one with normal form submission, so cannot use JSON.stringify({ username, password })
        let formdata = new URLSearchParams();
        // grant_type is needed by default fastapi provider otherise 422 unprocessable entity error.
        // try URLSearchParam, as FormData not working. It has tie to mordern browsers though. 
        formdata.append("grant_type", "password");
        formdata.append("username", username);
        formdata.append("password", password);
        const request = new Request( JWT_LOGIN_URL, {
            method: 'POST',
            body: formdata.toString(), 
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' })
            // headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token }) => setToken(access_token));  // Note, it is named access_token 
    },
    logout: () => {
        removeToken();
        return Promise.resolve();
    },

    checkAuth: () => {
        return getToken() ? Promise.resolve() : Promise.reject();
    },

    checkError: (error: { status: any; }) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            removeToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        return getToken() ? Promise.resolve() : Promise.reject();
    },
};

// for dev auth, no callback, just directly get the token via login form
export const AuthCallback = () => {
    return <></>
}