/**
 * Provide a way to view all the chats in a session  in natural conversation way.
 */

import React from "react";
import { useState } from "react";
import {NavLink} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Dialog,
  Typography,
  Avatar,
  Chip,
  Tooltip,
  Progress,
} from "@material-tailwind/react";

interface ConversationCardProps {
    chatList: Array<{ [key: string]: any }>;
}

export const ConverstaionCard: React.FC<ConversationCardProps> = ( {chatList} ) => {


    return (<>
      <div className="w-full px-5 flex flex-col justify-between">
        <div className="flex flex-col mt-5">
        {chatList.map((chat) => (<>
          <div className="flex justify-start mb-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
              className="object-cover h-8 w-8 rounded-full"
              alt=""
            />
            <div
              className="ml-2 py-3 px-4 bg-pink-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white"
            >
                {chat.last_request}
            </div>
          </div>
          <div className="flex justify-end mb-4">
            <div
              className="mr-2 py-3 px-4 bg-blue-500 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white"
            >
              {chat.last_response} 
            </div>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/240px-ChatGPT_logo.svg.png"
              className="object-cover h-8 w-8 rounded-full"
              alt=""
            />
          </div>
        </>))}
        </div>
      </div>
    </>)
}