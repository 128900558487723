import {NavLink} from "react-router-dom";

export const ChatNavLink = ( key: string, value: any) => {
    if(key === "session_id") {
        return (
            <NavLink to={`/dashboard/session/${value}`} 
                className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium"
            >
            {value}
            </NavLink>
        );
    } else { 
        return <>{value}</>
    }
}

export default ChatNavLink;