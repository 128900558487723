import {find} from "lodash";
import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Typography,
} from "@material-tailwind/react";
import { StatChart } from "../StatChart";
import { createBarChartOptions } from "../apexchart/ChartOptionsMaker";
import { JSONTree } from 'react-json-tree';

import { useGetDepartmentListQuery, useGetDepartmentChatStatListQuery } from "../../../apis/endpoints/admin/organizationService";
import { daysAgo } from "../../../../utils/datetime";


export const DepartmentList = () => {

    const { data, isLoading, isError } = useGetDepartmentListQuery();

    const { data: chatStatData, isLoading: chatStatIsLoading, isError: chatStatIsError } 
        = useGetDepartmentChatStatListQuery(
            { startTime: daysAgo(90).toISOString(), endTime: daysAgo(0).toISOString()}
        );

    if (isLoading) {
        return <>...loading</>;
    }
    if (isError) {
        return <>"Error: " + isError.toString()</>;
    }

    const departmentList: Array<{ [key: string]: any }> = data?.json;

    
    if (chatStatIsLoading) {
        return <>...loading</>
    }
    if (chatStatIsError) {
        return <>"Error: " + chatStatIsError.toString()</>
    }
    const chatStatList: Array<{ [key: string]: any }> = chatStatData?.json;

    // client side 'join'
    const result = chatStatList.map((stat) => {
        const dep = find( departmentList, { id: stat.department_id });
        if (dep) {
          return { ...stat, name: dep.name, description: dep.description };
        }
        return stat;
      });

    // for bar chart
    const categories = result.map((row) => row.name as string);
    const seriesName = "Chat Count";
    const seriesData = result.map((row) => row.count);
    const chartOptions = createBarChartOptions(seriesName, seriesData, categories);

    return(<>
        
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <Typography variant="h6" color="white">
           Chat Stat by Department (past 90 days)
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pb-2 pt-6">
            <StatChart chart={chartOptions} 
                title="Department # of Chats" 
                description="Chart numbers distribution per departments." 
                color="green"
                />

          <table className="w-full min-w-[640px] table-auto mt-6">
            <thead>
              <tr>
                {[
                  "Department",
                  "# of Chats",
                  "Description",
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
                {result.map((row, index) => { 

                    const className = `py-3 px-5 ${
                    index === result.length - 1
                        ? ""
                        : "border-b border-blue-gray-50"
                    }`;
                    return (
                    <tr >
                    <td className={className}>
                      <Typography className="text-xs font-thin text-blue-gray-600 w-min-450">
                        <NavLink to={`/dashboard/department/${row!.department_id}`} 
                          className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium w-min-450 block"
                        >
                                  {row.name}
                        </NavLink>
              
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.count}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.description}
                      </Typography>
                    </td>

                    </tr>
                )})}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
        {/* <JSONTree data={departmentList} />
        <JSONTree data={chatStatList} />     */}
        {/* <JSONTree data={result} />     */}
    </>);
}