import { 
    Toolbar,
    SaveButton,
} from 'react-admin';

// We don't need delete button, just update. May need confirmation dialog later. 
const SaveOnlyToolbar = () => (
    <Toolbar>
        <SaveButton label="Update" alwaysEnable />
    </Toolbar>
)

export default SaveOnlyToolbar;