import { isEmpty } from "lodash";
import React, {useState} from "react";
import { Link, NavLink } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  Button,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import type { colors } from "@material-tailwind/react/types/generic";
import type { color } from "@material-tailwind/react/types/components/button";

import { useSelector, useDispatch } from "react-redux";
import { selectLayoutState } from "./layoutSlice";

import { RouteGroup, Route } from "./NavRoutes";

// import { useMaterialTailwindController, setOpenSidenav } from "@/context";

interface SidenavProps {
  brandImg?: string;
  brandName?: string;
  // routes: { [key: string]: any }[];
  routes: RouteGroup[];
}

interface MenuNavLinkProps {
  layout: string;
  sidenavColor: string;
  sidenavType: string;
  icon: JSX.Element;
  name: String;
  path: String;
  subRoutes?: Route[];
}

const MenuNavLink: React.FC<MenuNavLinkProps> = ({
  layout,
  sidenavColor,
  sidenavType,
  icon,
  name,
  path,
  subRoutes,
}) => {
  console.log(">>>>> name", name, "subRoutes: ", subRoutes)
  if (isEmpty(subRoutes)) {
    console.log("   -- no sub route, just render the link", name);
    return (
      <NavLink to={`/${layout}${path}`}>
        {({ isActive }) => (
          <Button
            variant={isActive ? "gradient" : "text"}
            color={
              isActive
                ? (sidenavColor as color)
                : sidenavType === "dark"
                ? "white"
                : "blue-gray"
            }
            className="flex items-center gap-4 px-4 capitalize"
            fullWidth
          >
            {icon}
            <Typography color="inherit" className="font-medium capitalize">
              {name}
            </Typography>
          </Button>
        )}
      </NavLink>
    );
  } else {
    console.log("   -- with subroutes, now render them for ", name);
    return <MenuWithSubLevel  
      layout={layout}
      sidenavColor={sidenavColor}
      sidenavType={sidenavType}
      icon={icon}
      name={name}
      path={path}
      subRoutes={subRoutes}
    />;
  }

};

const MenuWithSubLevel: React.FC<MenuNavLinkProps> = ({
  layout,
  sidenavColor,
  sidenavType,
  icon,
  name,
  path,
  subRoutes,
}) =>{

  const [open, setOpen] = useState(1);

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Accordion
      open={open === 1}
      icon={<ChevronDownIcon
        strokeWidth={2.5}
        color="white"
        className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`} />}
    >
      <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
        <Typography color="white" className="mr-auto font-normal flex ml-1">
          {icon}
          <Typography color="inherit" className="font-medium capitalize ml-4">
            {name}
          </Typography>
        </Typography>
      </AccordionHeader>
      <AccordionBody className="py-1">
        <ul className="mb-4 flex flex-col gap-1">
          {subRoutes!.map(({ icon, name, path }) => (
            <li className="ml-4">
              <MenuNavLink
                layout={layout}
                sidenavColor={sidenavColor}
                sidenavType={sidenavType}
                icon={icon}
                name={name}
                path={path}
                subRoutes={undefined} />
            </li>
          ))}
        </ul>
      </AccordionBody>
    </Accordion>

    );
  }
// export function Sidenav({ brandImg, brandName, routes }) {
const Sidenav: React.FC<SidenavProps> = ({
  brandImg = "/img/logo.png",
  brandName = "PowerfAI",
  routes,
}) => {
  // const [controller, dispatch] = useMaterialTailwindController();
  // const { sidenavColor, sidenavType, openSidenav } = controller;
  const { sidenavColor, sidenavType, openSidenav } =
    useSelector(selectLayoutState);
  const dispatch = useDispatch();

  const sidenavTypes = {
    dark: "bg-gradient-to-br from-blue-gray-800 to-blue-gray-900",
    white: "bg-white shadow-lg",
    transparent: "bg-transparent",
  };

  // need to extend the keyof type, to cast string to color
  type SideNavTypesKeys = keyof typeof sidenavTypes;

  console.log("$$$$$$$$$$$ routes: ", routes);
  return (
    <aside
      className={`${sidenavTypes[sidenavType as SideNavTypesKeys]} ${
        openSidenav ? "translate-x-0" : "-translate-x-80"
      } fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0`}
    >
      <div
        className={`relative border-b ${
          sidenavType === "dark" ? "border-white/20" : "border-blue-gray-50"
        }`}
      >
        <Link to="/" className="flex items-center gap-4 py-6 px-8">
          <Avatar src={brandImg} size="sm" />
          <Typography
            variant="h6"
            color={sidenavType === "dark" ? "white" : "blue-gray"}
          >
            {brandName}
          </Typography>
        </Link>
        <IconButton
          variant="text"
          color="white"
          size="sm"
          ripple={false}
          className="absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden"
          // onClick={() => setOpenSidenav(dispatch, false)}
        >
          <XMarkIcon strokeWidth={2.5} className="h-5 w-5 text-white" />
        </IconButton>
      </div>
      <div className="m-4">
        {routes.map(({ layout, title, pages }, key) => (
          <ul key={key} className="mb-4 flex flex-col gap-1">
            {title && (
              <li className="mx-3.5 mt-4 mb-2">
                <Typography
                  variant="small"
                  color={sidenavType === "dark" ? "white" : "blue-gray"}
                  className="font-black uppercase opacity-75"
                >
                  {title}
                </Typography>
              </li>
            )}
            {pages.map(({ icon, name, path, subRoutes }) => (
              <li key={name}>
                <MenuNavLink
                  layout={layout}
                  sidenavColor={sidenavColor}
                  sidenavType={sidenavType}
                  icon={icon}
                  name={name}
                  path={path}
                  subRoutes={subRoutes}
                />
              </li>
            ))}
          </ul>
        ))}
      </div>
    </aside>
  );
};

export default Sidenav;
