import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { useSelector, useDispatch } from "react-redux";
// import { authValidate } from './auth/authSlice';
import { Admin, Resource, CustomRoutes} from "react-admin";
import { Route , Routes, Router, BrowserRouter} from 'react-router-dom';
// import {authProvider} from './auth/AuthProvider'
// import { authProvider, makeDataProvider, AuthCallback } from './auth/Auth0';
// import {authProvider, AuthCallback} from './auth/AuthPicker' 
import {authProvider, AuthCallback} from './auth/AuthProvider'

// for demo and testing only
// import jsonServerProvider from "ra-data-json-server";
import makeDataProvider from './auth/DataProvider';

import Landing from './features/landing/Landing'
import NotFound from './features/misc/NotFound'
import {Layout, BlankLayout} from './features/layout'

import chats from './features/chats'
import settings from './features/settings'
import CustSettings from './features/settings/CustSettings';  

// import { Gpt35SettingEdit } from './features/settings';
import {ConfigEdit} from './features/settings';
import alerts from './features/alerts'
import ChatDetailComponent from './features/chats/ChatDetail';

import AltDashboard from './features/altlayout/DashboardLayout';
import Home from './features/altlayout/elements/Home';
import OrgStruct from './features/altlayout/elements/OrgStruct';
import {DepartmentList} from './features/altlayout/widgets/department/DepartmentList';
import { DepartmentDetail } from './features/altlayout/widgets/department/DepartmentDetail';
import { ProjectList } from './features/altlayout/widgets/project/ProjectList';
import { ProjectDetail } from './features/altlayout/widgets/project/ProjectDetail';
import Event from './features/altlayout/elements/Event';
import Report from './features/altlayout/elements/Report';
import {Cases} from './features/altlayout/elements/Cases';
import {CaseDetail} from './features/altlayout/widgets/cases/CaseDetail';
import { ChatrecordList } from './features/chats/ChatRecordList';
import AlertList from './features/alerts/AlertList';
import { SessionView } from './features/altlayout/elements/SessionView';
import { SessionDetailCard } from './features/altlayout/widgets/sessions/SessionDetailCard';
import { SettingList } from './features/altlayout/widgets/settings/SettingList';
import { ChatGPTSetting } from './features/altlayout/widgets/settings/chatgpt/ChatGPTSetting';
import { SensitiveTermsSetting } from './features/altlayout/widgets/settings/terms/SensitiveTermsSetting';
import { EventAlertSetting } from './features/altlayout/widgets/settings/event/EventAlertSetting';
import { ReportSetting } from './features/altlayout/widgets/settings/report/ReportSetting';
import { ClaudeSetting } from './features/altlayout/widgets/settings/claude/ClaudeSetting';
import { BardSetting } from './features/altlayout/widgets/settings/bard/BardSetting';
import { ABTestSetting } from './features/altlayout/widgets/settings/abtest/ABTestSetting';
import { KeyMgmtSetting } from './features/altlayout/widgets/settings/keys/KeyMgmtSetting';


// const pickAuthPackage = () => {
//   switch (import.meta.env.VITE_REACT_APP_AUTH_PROVIDER) {
//     case "auth0":
//       return './auth/Auth0';
//     case "dev":
//       return './auth/AuthProvider';
//     default:
//       throw new Error("Invalid auth provider");
//   }
// }

// import { authProvider, makeDataProvider } from pickAuthPackage() as any;
// const { authProvider, makeDataProvider } = require(pickAuthPackage());
// top-level await will cause problem for prodcut build, even after switch to 
// esbuild and 'es2020' target.
// const { authProvider, AuthCallback } = await import(pickAuthPackage());

// const mock_data_url = 'https://jsonplaceholder.typicode.com';

//TODO: need to read it from either environment vars or configuration files from
//      BUILD time, or global variables from runtime. For now, we just read it 
//      from React build system directly (and hence the REACT_APP_ prefix), and 
//      relies on transpillers to do the work and compiling time. However, in the 
//      future, we may need to extend it w/ custom build script, especially for 
//      docker builds.

// const dev_data_url = process.env.REACT_APP_API_BASE_URL 
const dev_data_url = import.meta.env.VITE_REACT_APP_API_BASE_URL 

// const dataprovier = jsonServerProvider(dev_data_url);
const dataprovier = makeDataProvider(dev_data_url);

const custAdmin =  
    <Admin  dataProvider={dataprovier}
            authProvider={authProvider}
            layout={BlankLayout}
            dashboard={Landing}
            catchAll={NotFound}
            // basename='/admin'
            disableTelemetry>
          {/* <Resource name="chatrecord" {...chats} >
            <Route path="/:id/detail" element={<ChatDetailComponent />} />
          </Resource> */}
          {/* <Resource name="alert" {...alerts} /> */}
          {/* <Resource name="setting" {...settings} /> */}

          {/* <CustomRoutes noLayout>
            <Route path="/auth0-callback" element={<AuthCallback />} />
          </CustomRoutes> */}

          <Resource name="config" edit={<ConfigEdit />} />

          <CustomRoutes>
            <Route path="/allsettings" element={<CustSettings />} />
          </CustomRoutes>

          <CustomRoutes noLayout >
            <Route path="/dashboard" element={<AltDashboard />} >
              <Route path="home" element={<Home /> } />
              <Route path="department" element={<DepartmentList />} />
              <Route path="department/:id" element={<DepartmentDetail />} />
              <Route path="project" element={<ProjectList />} />
              <Route path="project/:id" element={<ProjectDetail />} />
              <Route path="wbs" element={<OrgStruct />} />
              <Route path="chatrecord" element={<ChatrecordList />} />
              <Route path="chatdetail/:id" element={<ChatDetailComponent />} />
              <Route path="alert" element={<Event />} />
              <Route path="report" element={<Report />} />
              <Route path="cases" element={<Cases />} />
              <Route path="case/:id" element={<CaseDetail />} />
              <Route path="sessions" element={<SessionView />} />
              <Route path="session/:id" element={<SessionDetailCard />} />
              <Route path="settings" element={<SettingList />} />
              <Route path="settings/chatgpt" element={<ChatGPTSetting />} />
              <Route path="settings/terms" element={<SensitiveTermsSetting />} />
              <Route path="settings/event" element={<EventAlertSetting />} />
              <Route path="settings/report" element={<ReportSetting />} />
              <Route path="settings/claude" element={<ClaudeSetting />} />
              <Route path="settings/bard" element={<BardSetting />} />
              <Route path="settings/abtest" element={<ABTestSetting />} />
              <Route path="settings/keys" element={<KeyMgmtSetting />} />
            </Route>
          </CustomRoutes>

    </Admin>

const App = () =>{ 

  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("App.tsx: App() useEffect() called");
  //   // dispatch(authValidate("")); 
  // }, []);

  return ( 

    // <Routes>
    //   <Route path="*" element={<AltDashboard />} />
    // </Routes>
<BrowserRouter 
  // basename='/admin'
  >
<Routes>
    {/* <Route path="/auth0-callback" element={<AuthCallback />} /> */}
    <Route path="*" element={custAdmin} />
</Routes>
</BrowserRouter>

  )
}

export default App
