// Note: GPT-3.5 Settings Edit Component
import { 
    Edit, 
    NumberInput, 
    SimpleForm, 
    TabbedForm,
    TextInput, 
    TextField,
    Toolbar,
    useRecordContext,
    SaveButton,
    useEditContext,
} from 'react-admin';
import SaveOnlyToolbar from './SaveOnlyToolbar';
import { JsonDebug } from '../../utils/JsonDebug';


const InternalGptEdit = () => { 
    // const {data, isLoading} = useRecordContext() 
    // if(isLoading) { 
    //     return (<div>Loading...</div>);
    // }

    var { record, isLoading} = useEditContext()
    if(isLoading) { return null;}

    console.log(">>>>record", record)

    return (
    // <Edit>
        <TabbedForm toolbar={<SaveOnlyToolbar />}>
            <TabbedForm.Tab label="GPT 3.5">
                <TextField source="id" />
                <NumberInput source="record.data['gpt-3.5-turbo'].price" />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="GPT 4">
                <div>8K context prcing</div>
                <NumberInput source="record.data['gpt-4']['context_8k'].price" />
                <div>32K context prcing</div>
                <NumberInput source="record.data['gpt-4']['context_32k'].price" />
            </TabbedForm.Tab>
        </TabbedForm>
    // </Edit>
    )
};

// const GptSettingEdit = () => { 
//     return ( 
//         <Edit>
//             <InternalGptEdit />
//         </Edit>
//     )
// }


// export default GptSettingEdit;

const Gpt35Edit = () => { 
    return (<InternalGptEdit />) 
}
export default Gpt35Edit 