/**
 * Date & time related utitlies, for simple operations, it is not needed 
 * to use moment.js and add bundle size. 
 */

import { clone } from "lodash";

export const daysAgo = (days: number) : Date => { 
    const now = new Date();
    // setDate will return number, which is timestamp, so we need to convert
    return new Date(now.setDate(now.getDate() - days));
}

/**
 * Round a date to the nearest {minuteCount} minutes 
 * @param date 
 * @param minuteCount 
 * @returns 
 */
export const roundToMinute = (date: Date, minuteCount: number = 1) : Date => {

    // milliseconds * seconds * minutes 
    const coeff = 1000 * 60 * minuteCount;
    // round to the nearest {minute} minutes
    return new Date(Math.round(date.getTime() / coeff) * coeff);
}


export const roundToHour = (date: Date, hourCount: number = 1) : Date => {
    // go for another implementation for better readability
    // make a clone of original date
    const cloneDate = new Date(date.getTime());
    // round it, if it is larger than 30, add 1 hour
    cloneDate.setHours(cloneDate.getHours() + Math.round(cloneDate.getMinutes()/60));
    // set the minutes and seconds to 0
    cloneDate.setMinutes(0);
    cloneDate.setSeconds(0);
    cloneDate.setMilliseconds(0);
    return cloneDate;
}