import React from 'react';
// Customized setting page, mount as custom route, bypass limits of resource
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useAuthenticated } from 'react-admin';

import {
    Card, 
    CardContent, 
    Chip,
    Box,
    Button, 
    // Accordion,
    // AccordionSummary,
    // AccordionDetails,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { 
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '../misc/CustAccordion';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';

import { 
    EditButton,
    useListContext,
    useRecordContext,
    useGetOne,
    useNotify,
    Title
} from 'react-admin';

import {fetchJson} from '../../auth/DataProvider';
import { JsonDebug } from '../../utils/JsonDebug';

const createApiUrl = (path: string) : string => {
   return import.meta.env.VITE_REACT_APP_API_BASE_URL +  path;
};

// No need to handle error here, delegate to React Query
const fetchAllSettings = () =>
    fetchJson(createApiUrl("/setting")).then(({ json }) => ({
        data: json,
    })) ;

// ).then(
//     ({ data }) => {
//         console.log(data);
//         notify('setting loaded');
//     }, 
//     (error) => {
//         console.log(error);
//         notify(
//             'setting failed to load', 
//             { type: "warning" }, 
//         )
//     }
// )

// It seems react-admin now is nothing but a wrapper around react-query, go for 
// raw flavor here
const useMySetting = () => {
    return useQuery({
        queryKey: ["all_settings"],
        queryFn: fetchAllSettings,
    });
}

// will step by step add more types 
interface PhraseItem { 
    message: string;
    model: string;
}

type  MessageListProps = { 
    data: PhraseItem[];
}

const MessageList: React.FC<MessageListProps> = (props) => {
    return (
    <>
    <TableContainer component={Paper} sx={{ml:8}} >
      <Table sx={{ minWidth: 400, maxWidth:700 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Message</TableCell>
            <TableCell align="right">Applied GPT Model</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {props.data.map((phrase)=> (
            <TableRow
              key={phrase.message}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {phrase.message}
              </TableCell>
              <TableCell align="right">
                 <Chip label={phrase.model} 
                    color="success" 
                    variant="outlined" 
                    icon={<DoneIcon/>}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
    )
} 

// for GPT 3.5 turbo model 
interface Gpt35Conf {
    "model": string,
    "pricing": {
        "token": number,
        "unit_price": number 
    }
}

const Gpt35Show: React.FC<{data: Gpt35Conf}> = (props) => {
    return (
    <>
    <Box sx={{mt:3}} >
        <Typography gutterBottom  align='left'>
        <b>gpt-3.5-turbo</b> is the powerful and efficient model offered by 
        <b>OpenAI</b>, which is the same model used by <b>ChatGPT</b> product.
        If offers one of the most cost effective solutions for your chatbot needs. 
        Token limit for this model is <b>4096</b> tokens.
        </Typography>
        <Stack direction="row" sx={{ml:8}}>
            <EditButton resource="config" record={{"id":"chatgpt.v3_5"}} label='Edit GPT-3.5 Pricing' />
        </Stack>
    </Box>
    <TableContainer component={Paper} sx={{ml:8}} >
      <Table sx={{ minWidth: 400, maxWidth:700 }} aria-label="GPT-3 pricing">
        <TableHead>
          <TableRow>
            <TableCell>Context Size</TableCell>
            <TableCell>Per Tokens</TableCell>
            <TableCell >Unit Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              key={props.data.pricing.token}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                4k 
              </TableCell>
              <TableCell component="th" scope="row">
                {props.data.pricing.token}
              </TableCell>
              <TableCell component="th" scope="row">
                {props.data.pricing.unit_price}
              </TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
    </>
    )
}

interface Gpt40Conf {
    "model": string,
    "pricing": {
        "context_32k": {
            "token": number,
            "unit_price": number 
        },
        "context_8k": {
            "token": number,
            "unit_price": number
        }
    }
}

const Gpt40Show: React.FC<{data: Gpt40Conf}> = (props) => {
    return (
    <>
    <Box sx={{mt:3}}>
        <Typography  gutterBottom  align='left'>
        <b>gpt-4</b> is the most powerful model offered by <b>OpenAI</b>, which 
        is still in preview release for selected customers. It supports different
        token limits, and price varies accordingly.
        Token limit for this 8k context model is <b>8192</b> tokens, and for 32K 
        context model, the limist is <b>32768</b>.
        </Typography>
        <Stack direction="row" sx={{ml:8}}>
            <EditButton resource="config" record={{"id":"chatgpt.v4"}} label='Edit GPT-4 Pricing' />
        </Stack>
    </Box>
    <TableContainer component={Paper} sx={{ml:8}} >
      <Table sx={{ minWidth: 200, maxWidth:300 }} aria-label="GPT-4 Billing" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Context Category</TableCell>
            <TableCell>Per Tokens</TableCell>
            <TableCell >Unit Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              key={"context_8k"}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                8K Context
              </TableCell>
              <TableCell component="th" scope="row">
                {props.data.pricing.context_8k.token}
              </TableCell>
              <TableCell component="th" scope="row">
                {props.data.pricing.context_8k.unit_price}
              </TableCell>
            </TableRow>

            <TableRow
              key={"context_32k"}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                32K Context
              </TableCell>
              <TableCell component="th" scope="row">
                {props.data.pricing.context_32k.token}
              </TableCell>
              <TableCell component="th" scope="row">
                {props.data.pricing.context_32k.unit_price}
              </TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
   </> 
    )
}

const CustSettings = () => {
    // need to enforce authenication for custom pages 
    useAuthenticated();

    // notify's signature now only accept 2 params
    const notify = useNotify();
    let record;

    const {data, isLoading} = useMySetting();
    if(isLoading){
        return (<div>Loading...</div>)
    }

    // console.log(">>>> all settings: ", data);
    // let k1 = "data" as keyof typeof data;
    // console.log("k1: ", k1);
    // console.log( data![k1]);
    // console.log(data!.data![0]!.data!.chatgpt!.v4!.model);

    let rootSettings = data!.data![0]!.data!;
    let gptSettings = rootSettings!.chatgpt!;
    let v3settings = gptSettings!.v3_5!;
    let v4Settings = gptSettings!.v4!;

    // for ai phrasing 
    let phraseSettings = rootSettings!.aiphrase!;
    

//     let settings: any; 
//     if(!!data) {
//         // need to do the shitty type casting here, otherwise tsc will complain
//         // and we are aware of the risk here, our response won't be undefined
//         // settings = (data as unknown as any[])[0]['data']; 
// console.log(data instanceof Array);
//     // console.log("> the settings: ", settings);
//     }

    return (
        <Card>
            <Title>Settings</Title>
            <CardContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>OpenAI GPT Pricing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography  align='left'>
                          <div>OpenAI currently offeres two completely different
                         billing models, based on models and context size.</div>
                        </Typography>

                        <Gpt35Show data={v3settings} />

                        <Gpt40Show data={v4Settings} />
                    
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>ChatGPT Courtesy Messages</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <MessageList data={phraseSettings} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Debug</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div" >
                          <div>Fetched JSON object:</div>
                          <JsonDebug data={data} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </CardContent>
        </Card>
    )
};

export default CustSettings;