import React, {useState} from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  List,
  ListItem,
  ListItemPrefix,
  Textarea,
  Typography,
} from "@material-tailwind/react";

import { fetchJson } from "../../../../auth/DataProvider";

import { 
  useGetCaseDetailQuery,
  useAddCommentMutation,
  useUpdateCaseStatusMutation,
} from "../../../apis/endpoints/admin/inspectionServcie";

import initials from "initials";

export const CaseDetail = () => {

  const [newComment, setNewComment] = useState("");

  const { id } = useParams();
  // need to parse it as number
  const caseId = parseInt(id as string);

  //TODO: fetch current user id
  const userId = "u001@example.com";

  // ------------------ add comment ------------------
  const addComment = useAddCommentMutation(caseId);

  const handleAddComment = async (caseId: number) => {
    console.log("caseId passed to handle add comment: ", caseId)
    const data = {
      comment: newComment,
      created_by: userId,
    };

    try {
        const result = await addComment.mutateAsync(data); 
        console.log("comment posted, result: ", result)
        setNewComment("");
    } catch (e) {
        console.log("error: ", e);
        // TODO: show error message to user
        return;
    }
    
  };

  // ------------------ change case status -----------------
  const updateCaseStatus = useUpdateCaseStatusMutation(caseId);
  const handleChangeStatus = async (caseId: number, status: string) => {
    try {
        const result = await updateCaseStatus.mutateAsync(status); 
        console.log("case status updated, result: ", result);
    } catch (e) {
        console.log("error: ", e);
        return;
    }
  }

  // ------------------ fetch case detail ------------------ 
  const { data, error, isLoading } = useGetCaseDetailQuery(caseId);

  if (isLoading) {
    return <>...loading</>;
  }
  if (error) {
    return <>"Error: " + error.toString()</>;
  }
  const caseDetail: { [key: string]: any } = data?.json;
  console.log("case detail data fetched: ", data);

  return (
    <>
      <Card>
        <CardBody>
          <ul className="flex flex-col gap-4 p-0 pt-12">
            <li key={"wbs_number"} className="flex items-center gap-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold capitalize"
              >
                Case #:
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-500"
              >
                {caseDetail!.id}
              </Typography>
            </li>
            <li key={"wbs_number"} className="flex items-center gap-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold capitalize"
              >
                Chat Session:
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-500"
              >
                {caseDetail!.session_id}
              </Typography>
            </li>
            <li key={"wbs_number"} className="flex items-center gap-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold capitalize"
              >
                Chat Record:
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-500"
              >

              <NavLink to={`/dashboard/chatdetail/${caseDetail!.chat_id}`} 
                className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium"
              >
                {caseDetail!.chat_id}
              </NavLink>
              </Typography>
            </li>
            <li key={"project"} className="flex items-center gap-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold capitalize"
              >
                Status:
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-500"
              >
                {caseDetail!.status} 

                {(caseDetail!.status === "open") &&( 
                  <Button variant="text" 
                    className="ml-10"
                    onClick={()=> handleChangeStatus(caseId, "closed")}
                  >
                    Close Ticket
                  </Button>
                )}

                {(caseDetail!.status === "closed") &&( 
                  <Button variant="text" 
                    className="ml-10"
                    onClick={()=> handleChangeStatus(caseId, "open")}
                  >
                    Reopen Ticket
                  </Button>
                )} 
              </Typography>
            </li>
            <li key={"description"} className="flex items-center gap-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold capitalize"
              >
                Description:
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-500"
              >
                {caseDetail!.description}
              </Typography>
            </li>
          </ul>

          <div className="mt-10 mb-40">
            <Typography>
              Comments:
            </Typography>
            <List>
              {caseDetail?.comments?.length > 0 && caseDetail?.comments?.map((comment: any, index: number) => (
                <ListItem key={comment.id}>
                  <Card className={
                            `w-full p-4
                            ${index % 2 === 0 ? "bg-gray-50" : "bg-gray-200"} 
                  `}
                  >
                  <div className="flex">

                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500">
                      <span className="text-xs font-bold text-white uppercase">{initials(userId)}</span>
                    </div>
                    
                    <div className="ml-10">
                      <div>
                        <Typography>
                          {comment.created_by} at {comment.created_at}
                        </Typography>
                      </div>
                      <div className="mt-2">
                      {comment.comment}
                      </div>
                    </div>
                  </div>
                  </Card>
                </ListItem>
              ))
              }
            </List>
          </div>

          {/* add new comment in textarea below and then click the button */}
          <div className="mt-10 mb-40">
            <Typography>
              Add new comment:
            </Typography>

            <Textarea 
                label="Description" 
                size="lg" 
                onChange={(e) => setNewComment(e.target.value)}
                value={newComment}
                className="mb-4"
             />

            <Button variant="gradient" 
                    onClick={()=> handleAddComment(caseId)} 
                    >
             Add Comments 
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
