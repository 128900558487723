// Generic configuration edit entry, load different components based on "id"
import { 
    Edit, 
    EditGuesser,
    useEditContext
} from 'react-admin';

import Gpt35Edit from './Gpt35Edit';
import Gpt40Edit from './Gpt40Edit';

import { JsonDebug } from '../../utils/JsonDebug';

const LoadConfigEdit = () => {
    var { record, isLoading} = useEditContext()
    if(isLoading) { return null;}
    
    // the id is the name of configuraiton section
    const sectionName = record["id"];
    let targetControl = null;
    console.log("sectionName:", sectionName)
    switch (sectionName) {
        case "chatgpt.v3_5":
            targetControl =  <Gpt35Edit/>;
            break;
        case "chatgpt.v4":
            targetControl =  <Gpt40Edit/>;
            // targetControl =  <EditGuesser/>;
            break;
        default:
            console.log('unknown configuration section name:', sectionName)
            throw Error("unknown configuration section name" + sectionName);
            break;
    }
    
    return targetControl;
    // return <JsonDebug data={record} />
}

const ConfigEdit = () => { 
    return (
        <Edit>
            <LoadConfigEdit />
        </Edit>
    )
}

export default ConfigEdit;