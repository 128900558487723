import React from "react";
import {
    ArrowDownOnSquareStackIcon,
    ArrowRightOnRectangleIcon,
    BellIcon,
    ChatBubbleLeftRightIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    DocumentMagnifyingGlassIcon,
    GlobeAltIcon,
    HomeIcon,
    Square2StackIcon,
    RectangleGroupIcon,
    TableCellsIcon,
    UserCircleIcon,
    UserPlusIcon,
    ViewfinderCircleIcon,
  } from "@heroicons/react/24/solid";

  import Home from './elements/Home'
  
  export interface Route {
    icon: JSX.Element;
    name: string;
    path: string;
    // element: JSX.Element;
    subRoutes?: Route[];
  }
  
  export interface RouteGroup {
    title?: string;
    layout: string;
    pages: Route[];
  }
  
  const icon = {
    className: "w-5 h-5 text-inherit",
  };
  
  const navroutes: RouteGroup[] = [
    {
      layout: "dashboard",
      pages: [
        {
          icon: <HomeIcon {...icon} />,
          name: "dashboard",
          path: "/home",
        },
        {
          icon: <RectangleGroupIcon {...icon} />,
          name: "Organization",
          path: "/",
          subRoutes: [
            {
              icon: <GlobeAltIcon {...icon} />,
              name: "Department",
              path: "/department",
            },
            {
              icon: <Square2StackIcon {...icon} />,
              name: "Project",
              path: "/project",
            },
            {
              icon: <CreditCardIcon {...icon} />,
              name: "WBS",
              path: "/wbs",
            },
          ],
        },
        {
          icon: <ViewfinderCircleIcon {...icon} />,
          name: "Analysis",
          path: "/",
          subRoutes: [
            {
              icon: <TableCellsIcon {...icon} />,
              name: "Chats",
              path: "/chatrecord",
            },
            {
              icon: <ChatBubbleLeftRightIcon {...icon} />,
              name: "Sessions",
              path: "/sessions",
            },
          ],
        },
        {
          icon: <BellIcon {...icon} />,
          name: "Events",
          path: "/alert",
        },
        {
          icon: <ArrowDownOnSquareStackIcon {...icon} />,
          name: "Reports",
          path: "/report",
        },
        {
          icon: <DocumentMagnifyingGlassIcon {...icon} />,
          name: "Investigation",
          path: "/cases",
        },
        {
          icon: <Cog6ToothIcon {...icon} />,
          name: "Settings",
          path: "/settings",
        },
      ],
    },
  ];
  
  export default navroutes;

