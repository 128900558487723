import {
  Box, 
  Icon,
} from '@mui/material';
// NOTE: need vite-plugin-svgr  plugin configuration support in Vite. 
//       will configure it later.
// import { ReactComponent as ChatgptLogo } from '../../assets/chatgpt.svg'; 
// import ChatgptLogo from '../../assets/chatgpt.svg';
// import UserIcon from '../../assets/user.svg';

// interface RoleRenderProps {
//   key: string;
//   value: any;
// }

const RoleRender = ( key: string, value: any) => {
    // return ( key == "assistant") 
    // ?  ( <Icon><ChatgptLogo /></Icon>)
    // : ( <Icon><UserIcon /></Icon>);

    return ( key == "assistant") 
    ?  ( <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/240px-ChatGPT_logo.svg.png' width='50px' height='50px'/>)
    :  ( <img src='https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png' width='50px' height='50px'/>)
}

export default RoleRender;