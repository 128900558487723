import { RootState, store } from "../app/store";
import { fetchUtils, DataProvider } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { getToken} from './InMemJwt'

// will fetch token from the global state, which will be independent of the stroage 
// it can be used by web worker, in memory, secured cookie or local storage, etc.
import { useSelector, useDispatch } from "react-redux";
import { selectIdToken } from './authSlice';

// The resource based URL is very cubersome and sometimes awkward, and we 
// also need to add custom route support. Needs to extend the data.

const idTokenSelector = (state: RootState) => state.auth.idToken;

export const fetchJson = (url : string, options: fetchUtils.Options = {}) => {
    // it is outside of function component, have to pass store rootstate and pass it to selector
    const idToken = idTokenSelector(store.getState());
    options.user = {
        authenticated: true,
        // need to pass Bearer explicitly, the fetchUtil won't add it for jwt 
        // token: `Bearer ${getToken()}`,
        token: `Bearer ${idToken}`,
    }

    return fetchUtils.fetchJson(url, options);
};

// we do need blob interface for binary download (such as PDF report)
export const fetchBlob = (url : string) => {
    const idToken = idTokenSelector(store.getState());
    const headers =  { 
        'Authorization': `Bearer ${idToken}`,
    }
    return fetch( url, {headers})
        .then(response => 
            response.blob().then(blob => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: blob,
            }))
        )
}

// export const fetchJsonWithPrefix = (
//     prefix:string, 
//     url : string, 
//     options: fetchUtils.Options = {}) => {
//     options.user = {
//         authenticated: true,
//         // need to pass Bearer explicitly, the fetchUtil won't add it for jwt 
//         token: `Bearer ${getToken()}`,
//     }

//     return fetchUtils.fetchJson(`${prefix}/${url}`, options);
// }


// const makeDataProvider = (url : string) => ( { 
//     ...simpleRestProvider(url, fetchJson),
//     // let's directly expose the apiClient for now, will switch to other pkg 
//     // such as axios in the future, if we ditch the react-admin commpletely.
//     // It will take time.
//     apiClient: fetchJsonWithPrefix,
// });

export const makeDataProvider= (url: string) => {
   return  simpleRestProvider(url, fetchJson);
};

// Let's extend the simpleRestProvider to allow arbitrary routes fetching with 
// arbitrary JSON data return without wrap it inside a { "data": json } format.
// Since it is from dedicated 3rd party lib, we cannot direclty modify -- it may
// be updated in the future by package provider. So we need to extend it, JS/TS
// is very flexible in this regard.

// const extendDataProvider = () => (
//     apiUrl: string,
//     httpClient = fetchUtils.fetchJson,
//     countHeader: string = 'Content-Range'
// ): DataProvider => ({
//     ...simpleRestProvider(apiUrl, httpClient, countHeader),
//     getExt: (url, data) => {
//         // your implementation here
//     }
// });

export default makeDataProvider;