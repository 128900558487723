import React, { useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Option,
  Select,
  Step,
  Stepper,
  Switch,
  Typography,
} from "@material-tailwind/react";
import { set } from "lodash";

interface Price {
  input: number;
  output: number;
}

interface Context {
  [key: string]: Price;
}

interface GPTModel {
  price: Context;
}

interface PriceConfig {
  [key: string]: GPTModel;
}

interface ModalConfig {
  temperature: number;
  top_p: number;
  max_tokens: number;
  presence_penalty: number;
  frequency_penalty: number;
}

const chatgptPricing: PriceConfig = {
    gpt35: {
      price: {
        context4k: {input: 0.0015, output: 0.002},
        context16k: {input: 0.003, output: 0.004},
      },
    },
    gpt40: {
      price: {
        context8k: {input: 0.03,  output: 0.06},
        context32k: {input: 0.06, output: 0.12},
      }
    }
};

const defaultModelConfig: ModalConfig = {
  temperature: 1,
  top_p: 1,
  max_tokens: 4096,
  presence_penalty: 0,
  frequency_penalty: 0,
};


export const ChatGPTSetting = () => {
  const [originalConf, setOriginalConf] = useState<ModalConfig>(defaultModelConfig);

  const [editMode, setEditMode] = useState(false);
  const [conf, setConf] = useState<ModalConfig>(originalConf);
  const [showDialog, setShowDialog] = useState(false);

  const handleEdit = () => {
    setConf(originalConf);
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    // get back original value
    setConf(originalConf);
  };

  const handleSave = () => {
    setShowDialog(true);
  };

  const handleConfirmSave = () => {
    setOriginalConf(conf);
    setEditMode(false);
    setShowDialog(false);
  };

  return (
    <>
      <div className="gird-cols-1 mb-12 grid gap-12 px-4  p-16 shadow-lg rounded-lg">
        <div className="ml-8">
          <Typography variant="h6" color="blue-gray" className="mb-3">
            ChatGPT API Settings
          </Typography>

          <div className="mb-8 ">
            <Typography
              variant="mb-4 block text-xs font-semibold  text-blue-gray-500"
              color="blue-gray"
              className="mb-3"
            >
              Default Model Parameters for ChatGPT
            </Typography>

          <div>

            <Typography variant="h6" color="blue-gray" className="mb-3">
              max_tokens
            </Typography>
            <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
              The maximum number of tokens to generate in the chat completion.
              The total length of input tokens and generated tokens is limited by the model's context length. 
            </Typography>
            <div className="flex flex-col gap-4 my-2 w-80">
              <Input
                type="text"
                value={conf.max_tokens}
                disabled={!editMode}
                onChange={(e) =>
                  setConf((prev) => ({ ...prev, max_tokens: parseInt(e.target.value) }))
                }
              />
            </div>

            <Typography variant="h6" color="blue-gray" className="mb-3">
              temperature
            </Typography>
            <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
              What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.
              OpenAI generally recommends altering this or top_p but not both.
            </Typography>
            <div className="flex flex-col gap-4 my-2 w-80">
              <Input
                type="text"
                value={conf.temperature}
                disabled={!editMode}
                onChange={(e) =>
                  setConf((prev) => ({ ...prev, temperature: parseInt(e.target.value) }))
                }
              />
            </div>


            <Typography variant="h6" color="blue-gray" className="mb-3">
              top_p
            </Typography>
            <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
              An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered.
              OpenAI generally recommends altering this or temperature but not both.
            </Typography>
            <div className="flex flex-col gap-4 my-2 w-80">
              <Input
                type="text"
                value={conf.top_p}
                disabled={!editMode}
                onChange={(e) =>
                  setConf((prev) => ({ ...prev, top_p: parseInt(e.target.value) }))
                }
              />
            </div>

            <Typography variant="h6" color="blue-gray" className="mb-3">
              presence_penalty
            </Typography>
            <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
              Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.
            </Typography>
            <div className="flex flex-col gap-4 my-2 w-80">
              <Input
                type="text"
                value={conf.presence_penalty}
                disabled={!editMode}
                onChange={(e) =>
                  setConf((prev) => ({ ...prev, presence_penalty: parseInt(e.target.value) }))
                }
              />
            </div>

            <Typography variant="h6" color="blue-gray" className="mb-3">
              frequency_penalty
            </Typography>
            <Typography variant="mb-4 block text-xs font-semibold  text-blue-gray-500" color="blue-gray" className="mb-3">
              Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.
            </Typography>
            <div className="flex flex-col gap-4 my-2 w-80">
              <Input
                type="text"
                value={conf.frequency_penalty}
                disabled={!editMode}
                onChange={(e) =>
                  setConf((prev) => ({ ...prev, frequency_penalty: parseInt(e.target.value) }))
                }
              />
            </div>


          {!editMode ? (
            <Button variant="outlined" className="mt-1 ml-2" onClick={handleEdit}>Edit</Button>
          ) : (
            <>
              <Button  variant="outlined" className="mt-1 ml-2" onClick={handleSave}>Save</Button>
              <Button  variant="outlined" className="mt-1 ml-2" onClick={handleCancel}>Cancel</Button>
            </>
          )}
          <Dialog
            size="sm"
            open={showDialog}
            handler={() => setShowDialog(false)}
          >
            <DialogHeader>
              Confirm Changes
            </DialogHeader>
            <DialogBody>
              <p className="text-base leading-relaxed text-gray-600 font-normal">
                Are you sure you want to save these changes?
              </p>
            </DialogBody>
            <DialogFooter>
              <Button
                color="red"
                variant="gradient"
                onClick={() => setShowDialog(false)}
                ripple
              >
                No
              </Button>

              <Button
                color="green"
                onClick={handleConfirmSave}
                ripple
              >
                Yes
              </Button>
            </DialogFooter>
          </Dialog>
        </div>


          </div>
        </div>

        <NavLink to="/dashboard/settings">
          <Button variant="gradient" className="mt-1 ml-6">
            Back
          </Button>
        </NavLink>
      </div>

    </>
  );
};
