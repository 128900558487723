/**
 * This is a wrapper component for StatsChart (which is mostly pure rendering), 
 * with all the data fetching logic and error handling, etc.
 * For now, it just grab from the RTK query apis, but it will be referectored 
 * to just dispatch proper redux action once we have time to do the integration 
 * between RTK Query and Redux, mainly the extraReducer builder thing.
 */

import React from "react";
import Chart from "react-apexcharts";
import { StatChart, StatChartProps} from "./StatChart";
import { daysAgo } from "../../../utils/datetime";
import {useQuery} from "react-query";
import {fetchJson} from "../../../auth/DataProvider";
// import { useGetMetricsAdminMetricsGetQuery } from "../../apis/adminEndpoints";
import { useMetricsQuery } from "../../apis/endpoints/admin/metricsService";
import {
  createBarChartOptions, 
  createLineChartOptions, 
  createPieChartOptions
} from "./apexchart/ChartOptionsMaker";
import { create } from "lodash";


//export interface MetricsStatChartPorps extends StatChartProps {
export interface MetricsStatChartPorps {
    chartType?: string;
    startTime?: string;
    endTime?: string;
    metrics: string[];
    dimension?: string;
    // too much hassle in assignment , if have limit it with "& {length: 2}"
    pieChartDataFields?: string[];

    // props from StatChart, we don't need 'chart' here
    color?: string;
    // chart: object;
    title: React.ReactNode;
    description: React.ReactNode;
    footer?: React.ReactNode;
}

// for pie chart, sometimes we need to convert the data to a different format
function convertArrayToObject(arr: any[], keyProp: string, valueProp: string) {
  return arr.reduce((acc, obj) => {
    const key = obj[keyProp];
    const value = obj[valueProp];
    // we get data with null key, just ignore it for now
    if (typeof key === 'string') {
      acc[key] = value;
    }
    // if is null or undefined, just change it to "unknown"
    if (typeof key === 'undefined' || key === null) {
      acc["unknown"] = value;
    }
    return acc;
  }, {});
}

export const MetricsStatChart: React.FC<MetricsStatChartPorps> = ({
    chartType = "bar",
    startTime = daysAgo(30).toISOString(), 
    endTime = daysAgo(0).toISOString(),
    metrics,
    dimension, 
    pieChartDataFields,
    ...rest
}) => {
    console.log("------------here we go, about to fetch now");

    // Weirdlly the RTK query use statement always set isLoading to true, and data to null now, 
    // even underlying query is successful (confirmed in dev tool nextwork tab). 
    // For now, switch back to raw react-query useQuery hook, which should work fine. 
    //TODO: need to wrap the useQuery hook in a custom hook to make it more reusable.
    // const { data, error, isLoading } = useQuery(
    //     ['metrics',  dimension, metrics],
    //     // ['metrics', startTime, endTime, dimension, metrics],
    //     () => fetchJson(
    //         `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/metrics?startTime=${startTime}&endTime=${endTime}&dimension=${dimension}&metrics=${metrics.join(',')}`,
    //         { method: 'GET' }
    //     )
    // )

    // refactory to external service endpoint hook
    const {data, error, isLoading} = useMetricsQuery({startTime, endTime, dimension, metrics});


    console.log(">>>> data: " , data, " error: ", error, " isLoading: ", isLoading);

    // const { data, error, isLoading } = useGetMetricsAdminMetricsGetQuery({
    //     startTime, endTime, dimension, metrics
    // });

    // console.log(">>>> data: " , data, " error: ", error, " isLoading: ", isLoading);

    if(isLoading) return <div>Loading...</div>;
    if(error) return <div>Error: {error.toString()}</div>;

    //const chart = makeBarcharOptions(data, dimension, metrics);

    // const chart = 
    //     chartType === "bar" 
    //     ? makeBarcharOptions(data!.json as any[], dimension, metrics) 
    //     : makeLineChartOptions(data!.json as any[], dimension, metrics);
    
    let chart: object = {};
    switch(chartType) {
        case "bar":
          chart = makeBarcharOptions(data!.json as any[], metrics, dimension!);
          break;
        case "line":
          chart = makeLineChartOptions(data!.json as any[], metrics, dimension!);
          break;
        case "pie":
          //we may need to convert the data first
          const pieData = pieChartDataFields
            ? [convertArrayToObject(
                data!.json as any[],
                pieChartDataFields![0],
                pieChartDataFields![1]
              )]
            : data!.json;
          console.log("pieData: ", pieData);
          chart = makePieChartOptions(pieData as any[]);
          console.log(JSON.stringify(chart));
          break;
        default:
          throw new Error("Invalid chart type: " + chartType);
    }

    // if(chartType === "pie") {
    //   return <Chart {...chart}  />;
    // }

    console.log(">>>> chart: " , chart)

    return <StatChart chart={chart} {...rest} />;
}



// the metrics and returned value are different, so we need to maintian the relationship at least for now
const metricsMap: {[key:string]: string} = {
    "request": "request_count",
    "user": "user_distinct_count",
    "session": "session_distinct_count",
}

const makeBarcharOptions = (
        data: any[], 
        metrics: string[], 
        dimension:string,
        ) => {

    // const data = [
    //     {
    //         "hour": "2023-04-02",
    //         "request_count": 1
    //     },
    //     {
    //         "hour": "2023-04-06",
    //         "request_count": 4
    //     },
    //     {
    //         "hour": "2023-04-09",
    //         "request_count": 18
    //     },
    // ] 
    console.log("Inside make barchat option, we got data: ", data, ", dimension: ", dimension, ", metrics: ", metrics);
    // break above data into two columns array
    const categories = data.map((item) => item[dimension]);
    console.log(">>>> categories: ", categories)

    //TODO: may need to lookup from a map later.
    const colname = metricsMap[metrics[0]];
    const series = data.map((item) => item[colname]);
    console.log(">>>> series: ", series)
            
    // construct options in the immutable way, for now just manually craft it
    // may switch to immer, but it is also not easy to use outside of redux reducer 
    return createBarChartOptions(colname, series, categories);

};

const makeLineChartOptions = (
        data: any[],
        metrics: string[],
        dimension:string,
    ) => {
    const categories = data.map((item) => item[dimension]);
    const colname = metricsMap[metrics[0]];
    const series = data.map((item) => item[colname]);

    return createLineChartOptions(colname, series, categories);
};


const makePieChartOptions = (
  data: any[],
) => { 

    // pie chart is different, we need labels, the dimension most likely
    // is null, and metrics has more than one element. Typical data resp:
    //[
    //   {
    //     "pii_email_bool_sum": 7,
    //     "pii_ssn_bool_sum": 2,
    //     "pii_credit_card_bool_sum": null,
    //     "pii_username_bool_sum": null,
    //     "pii_password_bool_sum": null
    //   }
    // ]

  // get keys of x[0] as an array
    const d = data[0];
    const labels = Object.keys(d).map((key) => legendDisplayName(key));
    const series = Object.keys(d).map((key) => d[key] ?? 0 );

    return createPieChartOptions(labels, series);
};


const legendNameMap: {[key:string]: string} = {
  "pii_email_bool_sum": "Email",
  "pii_ssn_bool_sum": "SSN",
  "pii_credit_card_bool_sum": "Credit Card",
  "pii_username_bool_sum": "Username",
  "pii_password_bool_sum": "Password",
};

const legendDisplayName = (columnName: string) : string => { 
  // For now just return the lookup table entry, but it may get more complicated, 
  // as it may not be just sum, can be other value like average, etc. And we need 
  // some better way to handle it.
  // For column name that is not in the list, just return the original name
  return legendNameMap[columnName] ?? columnName;
};