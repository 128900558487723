
import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Switch,
  Typography,
} from "@material-tailwind/react";


export const reportSwitchData = [
    {
      title: "Reports",
      options: [
        {
          checked: true,
          label: "Enble security/sensitive data filtering report",
        },
        {
          checked: false,
          label: "Enable Project Breakdown Report",
        },
        {
          checked: true,
          label: "Enable Cost Analysis Report",
        },
        {
          checked: true,
          label: "Enable topics discovery report",
        },
      ],
    },
    {
      title: "Report Frequency",
      options: [
        {
          checked: false,
          label: "Create daily report",
        },
        {
          checked: true,
          label: "Create monthly report",
        },
        {
          checked: false,
          label: "Create quarterly report",
        },
      ],
    },

    {
      title: "Upload Report",
      options: [
        {
          checked: false,
          label: "Upload report to SharePoint Online.",
        },
        {
          checked: false,
          label: "Upload report to S3 bucket.",
        },
      ],
    },


  ];


export const ReportSetting = () => {

    return (<>

    <div className="gird-cols-1 mb-12 grid gap-12 px-4 lg:grid-cols-2 xl:grid-cols-3 p-16 shadow-lg rounded-lg">
            <div className="ml-8">
              <Typography variant="h6" color="blue-gray" className="mb-3">
                Report Settings
              </Typography>
              <div className="flex flex-col gap-12">
                {reportSwitchData.map(({ title, options }) => (
                  <div key={title}>
                    <Typography className="mb-4 block text-xs font-semibold uppercase text-blue-gray-500">
                      {title}
                    </Typography>
                    <div className="flex flex-col gap-6">
                      {options.map(({ checked, label }) => (
                        <Switch
                          key={label}
                          id={label}
                          label={label}
                          defaultChecked={checked}
                          labelProps={{
                            className: "text-sm font-normal text-blue-gray-500",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ))}

                <div className="flex flex-col gap-6 w-full">
                    <Typography className="mb-4 block text-xs font-semibold uppercase text-blue-gray-500">
                     SharePoint Drop Folder URL 
                    </Typography>
                    <Input disabled={true} labelProps={{shrink:false, visuallyHidden: false,}} label="SharePoint Drop Folder URL" value="https://powerfai.sharepoint.com/sites/dummysite/Shared%20Documents/" />
                    <Typography className="mb-4 block text-xs font-semibold uppercase text-blue-gray-500">
                     S3 Bucket URL 
                    </Typography>
                    <Input disabled={true} label="S3 Bucket URL" value="powerfai-report-drop.s3.amazon.com" />
                </div>
              </div>
            </div>

    </div>
                

    <NavLink to="/dashboard/settings" >
        <Button variant="gradient" className="mt-1" >
        Back 
        </Button>
    </NavLink>
    
    </>);
}