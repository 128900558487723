/**
 * Service hooks for the metrics API. For now use the fetchJson wrapper from react-admin, 
 * which can be easily replaced with a custom fetcher with same function signature. Also
 * use react-query for caching and refetching -- our autogenerated API hooks (RTK Query)
 * seems having some issue with isLoading props, may reconsider that later after debugging.
 * 
 * If later on, we go for deeper redux integration, services can stil be merged via the
 * extraReducers, with some async thunks help.
 */

import {useQuery} from "react-query";
import {fetchJson} from "../../../../auth/DataProvider" 
import {daysAgo, roundToMinute } from "../../../../utils/datetime";


interface MetricsQueryProps {
    startTime?: string;
    endTime?: string;
    dimension?: string;
    metrics: string[];
}


export const useMetricsQuery = ({
    startTime = daysAgo(30).toISOString(), 
    endTime = daysAgo(0).toISOString(),
    metrics,
    dimension = undefined, 
    }: MetricsQueryProps) => {

    // dimension may be optional, but start/end time will actually have default
    // the server side code cannot accept the typical "," separated list for metrics, so need some 
    // special treatment here
    const repeatQueryStr = metrics.map(m => `metrics=${m}`).join('&');
    const metricsQueryStr = dimension 
        ? `?startTime=${startTime}&endTime=${endTime}&dimension=${dimension}&${repeatQueryStr}` 
        : `?startTime=${startTime}&endTime=${endTime}&${repeatQueryStr}` ;

    return useQuery(
        // TODO: cached key needs to be carefully considered, if the start/end time is constantly changing 
        //       there will be too many calls to API server. However, we also wants to refresh the data on 
        //       time, so may play with cache policy later.
        // ['metrics', startTime, endTime, dimension, metrics],
        ['metrics',  dimension, metrics],
        // the fetcher function
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/metrics?${metricsQueryStr}`,
            { method: 'GET' }
        )
    )
}

