
import React from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Checkbox,
  Chip,
  Dialog, 
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  TabsBody,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import { NavLink, useParams } from "react-router-dom";
import { SessionCard } from "../sessions/SessionCard";
import { CasesListCard } from "../cases/CasesListCard";
import { BookOpenIcon, BriefcaseIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";
import underDevImg from "../../../../assets/under_development.jpg"; 

export const promptData = [ 
    {titile:"Catchy", description: "Generate a tagline for our new product that is short, catchy, and memorable.", published: true },
    {titile:"Upbeat & Excitement", description: "Generate a tagline that is positive and upbeat, and that conveys a sense of excitement and anticipation.", published: true },
    {titile:"Uniqueness", description: "Develop a tagline that is original and creative, and not already in use by another company.", published: true },
    {titile:"Accuracy", description: "Create a tagline that accurately reflects the benefits of our product and appeals to our target audience.", published: false },
    {titile:"Impersonate: Jedi", description: "Act like Jedi in starwar, use the Force but be mindful of your thoughts and actions, and strive to bring peace and justice to the galaxy.", published: true },
    {titile:"Impersonate: Shakespear", description: "You are an assistant that speaks like Shakespear.", published: true },
    // {titile:"", description: "", published: false },
]

export const SysPromptCard: React.FC<{projId:string}> = ({projId}) => {

    // for dialog
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen((cur) => !cur);

    if( !projId || projId != "11") return <></>;
console.log("url ------------------------------", import.meta.env.BASE_URL)

    return (<>

<div className="grid grid-cols-12 max-w-5xl gap-4">

{ promptData.map((item, index) => (
    
    <div className=" grid col-span-4 relative">

    <p className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-sm py-6 pr-6 pl-9" >
        <p className="pb-4">
        <Chip
            value={item.published ? "Published" : "Draft"}
            variant="ghost"
            color={ item.published ? "green" : "indigo"}
            icon={
            <Checkbox
                color= { item.published ? "green" : "indigo"}
                ripple={false}
                containerProps={{ className: "p-0" }}
                checked={true}
                // className="-ml-px border-2 border-green-900 before:hidden checked:border-green-900 checked:bg-green-900"
                className="-ml-px border-2"
            />
            }
        />
        </p>
        <p className="text-2xl font-bold text-gray-500 group-hover:text-gray-700">
            {item.titile}
        </p>
        <p className="text-sm font-semibold text-gray-500 group-hover:text-gray-700 mt-2 leading-6">
            {item.description}
        </p>
        <p className="">
            <Button variant="outlined" color="blue" size="sm" className="mt-4" onClick={handleOpen}>
                Edit
            </Button>
        </p>
        <div className="bg-blue-700 group-hover:bg-blue-600 h-full w-4 absolute top-0 left-0"> </div>
    </p>
    </div>
    ))
}

</div>

    <Dialog size="xs" open={open} handler={handleOpen}>
        <DialogHeader>
        </DialogHeader>
        <DialogBody>
            <img
                alt="under development"
                className="h-[48rem] w-full object-cover object-center"
                src={underDevImg}
                // src={import.meta.env.BASE_URL + "/img/under_development.jpg"}
            />
        </DialogBody>
        <DialogFooter>
        </DialogFooter>
    </Dialog>

    </>)
}

export const ProjectDetail = () => {

    const { id } = useParams();

    return (
        <>
        <div className="pt-6">

        <Tabs value="chat table" className="w-full">
              <TabsHeader
                className=" w-80"
                indicatorProps={{
                  className: "bg-blue-500/10 shadow-none text-blue-500",
                }}
              >
                  <Tab key={"chat table"} value={"chat table"}>
                    <div className="flex items-center gap-2">
                      <ChatBubbleLeftRightIcon className="h-5 w-5" />
                      Sessions 
                    </div>
                  </Tab>
                  <Tab key={"conversation"} value={"conversation"}>
                    <div className="flex items-center gap-2">
                      <BriefcaseIcon className="h-5 w-5" />
                      Cases/Tickets 
                    </div>
                  </Tab>
                  <Tab key={"system prompt"} value={"system prompt"}>
                    <div className="flex items-center gap-2 w-40">
                      <BookOpenIcon className="h-5 w-5" />
                      System Prompts 
                    </div>
                  </Tab>
              </TabsHeader>

              <TabsBody>
                  <TabPanel key={"chat table"} value={"chat table"}>
                    <Typography className="pb-4">
                       Chat Sessions for the past 90 days
                    </Typography>
                    <SessionCard  projectId={id} />
                  </TabPanel>
                  <TabPanel key={"conversation"} value={"conversation"}>
                    <Typography className="pb-0 mt-12">
                       Open Investigation Cases 
                    </Typography>
                    <CasesListCard projectId={parseInt(id!)} showHeader={false} />
                  </TabPanel>
                  <TabPanel key={"system prompt"} value={"system prompt"}>
                    <Typography className="pb-8 mt-2">
                       System Prompts for current project. 
                    </Typography>
                    <SysPromptCard projId={id!} />
                  </TabPanel>
              </TabsBody>
        </Tabs>
        </div>
        </>
    )

}
