import { compact } from "lodash";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { fetchJson } from "../../../../auth/DataProvider";
import { daysAgo, roundToMinute } from "../../../../utils/datetime";
import { start } from "repl";

interface ListQueryProps {
  startTime?: string;
  endTime?: string;
  departmentId?: number;
  projectId?: number;
  wbs?: string;
  status?: string;
}

/** Getall cases without addtional filtering */
export const useListInspectionCasesQuery = ({
  startTime = daysAgo(30).toISOString(),
  endTime = daysAgo(0).toISOString(),
  departmentId,
  projectId,
  wbs,
  status,
}: ListQueryProps) => {
  // set proper cache key to avoid too many calls
  const _start = roundToMinute(new Date(startTime));
  const _end = roundToMinute(new Date(endTime));

  const filterOp = projectId ? "byProject" : departmentId ? "byDepartment" : wbs? "byWbs" : "listAll";

  const _startQstr = startTime ? `&start_date=${startTime}` : "";
  const _endQstr = endTime ? `&end_date=${endTime}` : "";
  const _deptQstr = departmentId ? `&department_id=${departmentId}` : "";
  const _projQstr = projectId ? `&project_id=${projectId}` : "";
  const _wbsQstr = wbs ? `&wbs=${wbs}` : "";
  const _statusQstr = status ? `&status=${status}` : "";
  const _qstr = compact([_startQstr, _endQstr, _deptQstr, _projQstr, _wbsQstr, _statusQstr]).join("&");
  

  return useQuery(["list_inspection_cases", filterOp, _start, _end], () =>
    fetchJson(
      `${
        import.meta.env.VITE_REACT_APP_API_BASE_URL
      }/cases?${_qstr}`,
      { method: "GET" }
    )
  );
};

// /** Get cases for specific department, use different cache key */
// export const useListInspectionCasesForDepartmentQuery = ({
//   startTime = daysAgo(30).toISOString(),
//   endTime = daysAgo(0).toISOString(),
//   departmentId,
// }: ListQueryProps) => {
//   // set proper cache key to avoid too many calls
//   const _start = roundToMinute(new Date(startTime));
//   const _end = roundToMinute(new Date(endTime));

//   return useQuery(["list_inspection_cases_for_dept", departmentId, _start, _end], () =>
//     fetchJson(
//       `${
//         import.meta.env.VITE_REACT_APP_API_BASE_URL
//       }/cases?start_date=${startTime}&end_date=${endTime}&department_id=${departmentId}`,
//       { method: "GET" }
//     )
//   );
// };


// /** Get cases for specific project, use different cache key */
// export const useListInspectionCasesForProjectQuery = ({
//   startTime = daysAgo(30).toISOString(),
//   endTime = daysAgo(0).toISOString(),
//   projectId,
// }: ListQueryProps) => {
//   // set proper cache key to avoid too many calls
//   const _start = roundToMinute(new Date(startTime));
//   const _end = roundToMinute(new Date(endTime));

//   return useQuery(["list_inspection_cases_for_project", projectId, _start, _end], () =>
//     fetchJson(
//       `${
//         import.meta.env.VITE_REACT_APP_API_BASE_URL
//       }/cases?start_date=${startTime}&end_date=${endTime}&project_id=${projectId}`,
//       { method: "GET" }
//     )
//   );
// };


export const useGetCaseDetailQuery = (caseId: number) => {
  const _lastfetch = roundToMinute(new Date());
  return useQuery(
    // we may need to constantly fetch inspection data, will have mutation later.
    ["inspection_case", caseId, _lastfetch],
    // the fetcher function
    () =>
      fetchJson(
        `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/case/${caseId}`,
        { method: "GET" }
      )
  );
};

// interface AddCommentProps {
//     caseId: number;
//     data: {[key: string]: any};
// }
export const useAddCommentMutation = (caseId: number) => {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: (data: { [key: string]: any }) => {
      return fetchJson(
        `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/case/${caseId}/comment`,
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    },

    onSuccess: () => {
      // Invalidate and refetch, we don't want to fire & forget, wait till it is done.
      return cache.invalidateQueries(["inspection_case", caseId]);
    },
  });
};

export const useUpdateCaseStatusMutation = (caseId: number) => {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: (status: string) => {
      return fetchJson(
        `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/case/${caseId}`,
        {
          method: "PUT",
          body: JSON.stringify({ status }),
        }
      );
    },

    onSuccess: () => {
      return cache.invalidateQueries(["inspection_case", caseId]);
    },
  });
};
