/**
 * View and search wbs number mapping details.
 */

import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Combobox } from "@headlessui/react";
import {
  Card,
  CardHeader,
  CardBody,
  Dialog,
  Typography,
  Avatar,
  Chip,
  Tooltip,
  Progress,
  CardFooter,
  Tab,
  Tabs,
  TabsHeader,
  TabsBody,
  TabPanel,
} from "@material-tailwind/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { daysAgo } from "../../../utils/datetime";
import { useWBSQuery } from "../../apis/endpoints/admin/organizationService";
import { CheckIcon } from "@heroicons/react/20/solid";
import { SessionCard } from "./sessions/SessionCard";
import { CasesListCard } from "./cases/CasesListCard"; 
import { BookOpenIcon, BriefcaseIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";

// const WbsSearch = () => {
//   return (
//     <>

//     </>
//   )
// }

export const WbsCard = () => {
     
  const [selectedWbs, setSelectedWbs] = useState<{ [key: string]: any }>({});

  // query here is the user input in the combo box
  const [query, setQuery] = useState("");

  const { data, error, isLoading } = useWBSQuery();
  console.log("wbs data: ", data);

  if (isLoading) {
    return <>...loading</>;
  }
  if (error) {
    return <>"Error: " + error.toString()</>;
  }

  const wbsData: Array<{ [key: string]: any }> = data?.json;


  const handleChange = (event: { [key: string]: any }) => {
    console.log("event: ", event);
    setSelectedWbs(event);
    // clear the query 
    setQuery("");
  };

  // sample data
  //   [
  //   {
  //     "status": 1,
  //     "description": "Grammar enhancement AI assistant integration",
  //     "project_id": 1,
  //     "wbs_number": "1206010000001",
  //     "project": {
  //       "description": "Aims to improve employee engagement, performance & reduce turnover.",
  //       "name": "My Performance Review",
  //       "department_id": 2,
  //       "id": 1,
  //       "department": {
  //         "name": "Human Resources",
  //         "description": "Responsible for hiring, training, and developing the organization's employees.",
  //         "id": 2
  //       }
  //     }
  //   },...]

  const filteredWbs =
    query === ""
      ? wbsData
      : wbsData.filter((wbs) => {
          // we search wbs number, and project name and department name
          return (
            wbs.wbs_number.toString().includes(query.toLowerCase()) ||
            wbs.description.toLowerCase().includes(query.toLowerCase()) ||
            wbs.project.name.toLowerCase().includes(query.toLowerCase()) ||
            wbs.project.department.name
              .toLowerCase()
              .includes(query.toLowerCase())
          );
        });

  return (
    <>
      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            <Typography variant="h6" color="white">Organaiztion WBS/Project View</Typography>
          </CardHeader>
          <CardBody className="overflow-x-scroll px-0 pt-0 pb-2 ml-10">
            <Typography variant="h6" color="blue-gray" className="mb-2">
            Please input the WBS number, project name or department name to filter: 
            </Typography>
            <Combobox value={selectedWbs} onChange={handleChange}>
              <Combobox.Input
                onChange={(event) => setQuery(event.target.value)}
                className={"border border-blue-500 rounded-md px-4 py-2 text-sm leading-5 focus:outline-none focus:border-green-500 w-96"}
              />
              <Combobox.Options>
                {filteredWbs.map((wbs) => (
                  <Combobox.Option key={wbs.wbs_number} value={wbs}>
                    {({ active, selected }) => (
                      <div
                        className={`${
                          active
                            ? "bg-blue-500 text-white"
                            :  selected? "bg-pink-500 text-white" : "bg-white text-black" 
                        }`}
                      >
                        {/* {selected && <CheckIcon className="ml-2 h-6 w-6 text-gray-500" />} */}
                        {wbs.wbs_number}: {wbs.project.name},{" "}
                        {wbs.project.department.name}, {wbs.description}
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Combobox>

            { Object.keys(selectedWbs).length > 0  && (
              <>
              {/* // <Card>
              //   <CardBody className="overflow-x-scroll px-0 pt-0 pb-2  mt-10"> */}
                  <ul className="flex flex-col gap-4 p-0 pt-12">
                      <li key={"wbs_number"} className="flex items-center gap-4">
                        <Typography variant="small" color="blue-gray" className="font-semibold capitalize" >
                          WBS:
                        </Typography>
                        <Typography variant="small" className="font-normal text-blue-gray-500" >
                          {selectedWbs!.wbs_number}
                        </Typography>
                      </li>
                      <li key={"description"} className="flex items-center gap-4">
                        <Typography variant="small" color="blue-gray" className="font-semibold capitalize" >
                          Description:
                        </Typography>
                        <Typography variant="small" className="font-normal text-blue-gray-500" >
                          {selectedWbs!.description}
                        </Typography>
                      </li>
                      <li key={"project"} className="flex items-center gap-4">
                        <Typography variant="small" color="blue-gray" className="font-semibold capitalize" >
                          Project:
                        </Typography>
                        <Typography variant="small" className="font-normal text-blue-gray-500" >
                          <NavLink 
                            to={`/dashboard/project/${selectedWbs!.project!.id}`}
                            className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium w-min-450 block"
                          >
                            {selectedWbs!.project!.name}
                          </NavLink>
                        </Typography>
                      </li>
                      <li key={"department"} className="flex items-center gap-4">
                        <Typography variant="small" color="blue-gray" className="font-semibold capitalize" >
                          Department:
                        </Typography>
                        <Typography variant="small" className="font-normal text-blue-gray-500" >
                          <NavLink 
                            to={`/dashboard/department/${selectedWbs!.project!.department!.id}`}
                            className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium w-min-450 block"
                          >
                            {selectedWbs!.project!.department!.name}
                          </NavLink>
                        </Typography>
                      </li>
                  </ul>

                  <div className="mt-10 mb-40">


        <Tabs value="chat table" className="w-full">
              <TabsHeader
                className=" w-80"
                indicatorProps={{
                  className: "bg-blue-500/10 shadow-none text-blue-500",
                }}
              >
                  <Tab key={"chat table"} value={"chat table"}>
                    <div className="flex items-center gap-2">
                      <ChatBubbleLeftRightIcon className="h-5 w-5" />
                      Sessions 
                    </div>
                  </Tab>
                  <Tab key={"conversation"} value={"conversation"}>
                    <div className="flex items-center gap-2">
                      <BriefcaseIcon className="h-5 w-5" />
                      Cases/Tickets 
                    </div>
                  </Tab>
                  {/* <Tab key={"system prompt"} value={"system prompt"}>
                    <div className="flex items-center gap-2 w-40">
                      <BookOpenIcon className="h-5 w-5" />
                      System Prompts 
                    </div>
                  </Tab> */}
              </TabsHeader>

              <TabsBody>
                  <TabPanel key={"chat table"} value={"chat table"}>
                    <Typography>
                      Chat sessions (in past 90 days) was found for this WBS.
                    </Typography>
                    <SessionCard wbs={selectedWbs!.wbs_number} />
                  </TabPanel>
                  <TabPanel key={"conversation"} value={"conversation"}>
                    <Typography className="pb-0 mt-12">
                        Open Investigation Cases 
                    </Typography>
                    <CasesListCard wbs={selectedWbs!.wbs_number} showHeader={false} />
                  </TabPanel>
                  <TabPanel key={"system prompt"} value={"system prompt"}>
                  </TabPanel>
              </TabsBody>
        </Tabs>

                    
                  </div>
              {/* //   </CardBody>
              // </Card> */}
              </>
            )}
          </CardBody>

          <CardFooter className="mt-6 flex items-center justify-between py-0 px-1 pb-40">
            &nbsp;
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default WbsCard;
