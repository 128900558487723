import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import WbsCard from "../widgets/WbsCard";

export const OrgStruct = () => {
  return <>
    <WbsCard />
  </>;
};

export default OrgStruct;
