/**
 * The form that is used to create a new case. It is used by chat
 * or session detail pages.
 */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Checkbox,
  Dialog,
  Input,
  Radio,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { error } from "console";
import { fetchJson } from "../../../auth/DataProvider";

interface CreateCaseFormProps {
    chatId: number;
    sessionId: string;
}

export const CreateCaseForm: React.FC<CreateCaseFormProps> = ({
    chatId, 
    sessionId
}) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => { 
    // reset values 
    setDescription("");
    setTags("security");
    setErrors({});
    setOpen((cur) => !cur);
  }

  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("security");

  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const navigate = useNavigate();

  const handleClick = async () => {

    // clear all previous errors
    setErrors({});

    // will switch to Hook Forms or Yup later, now just go for simplest validation
    if (!description || description.trim().length === 0) {
      setErrors({"description": "* Description can cannot be empty or blank."});
      return;
    } else {
      setErrors({});
    }    

    const data = {
      session_id: sessionId,
      chat_id: chatId,
      tags: tags,
      description: description,
    };

    try {
        const result = await fetchJson(`${import.meta.env.VITE_REACT_APP_API_BASE_URL}/case`, {
            method: 'POST',
            body: JSON.stringify(data),
        })
        console.log("result: ", result);
       
        //TODO: should redirect to the case detail page or just the case list page?
        navigate(`/dashboard/cases`);

    } catch (e) {
        console.log("error: ", e);
        setErrors({"description": "* Failed to create case, please try again later."});
        return;
    }

    // console.log(">>>>>>>>>>>>>>>>form data: ", data);
    
  };
  return (
    <>
      <Button onClick={handleOpen}>Create Investgation/Follow-up Case</Button>
      <Dialog
        size="md"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[48rem]">
          <CardHeader
            variant="gradient"
            color="blue"
            className="mb-4 grid h-16 place-items-center"
          >
            <Typography variant="h4" color="white">
             Create Case 
            </Typography>
          </CardHeader>
          <CardBody className="flex flex-col gap-4">
            <div>
            { 
                Object.keys(errors).map((key) => (
                    <Typography variant="small" color="red">
                        {errors[key]}
                    </Typography>
                ))  
            }
            </div>
            <Textarea 
                label="Description" 
                size="lg" 
                onChange={(e) => setDescription(e.target.value)}
             />
            <div className="gap-10">
                <Typography variant="h6" color="blue-gray">
                Please pick a case type:
                </Typography>
                <Radio
                    id="security"
                    name="type"
                    label="Security"
                    ripple={true}
                    value="security"
                    checked={tags === "security"}
                    onChange={(e) => setTags(e.target.value)} 
                />
                <Radio
                    id="follow-up"
                    name="type"
                    label="Follow-up"
                    value="follow-up"
                    ripple={false}
                    checked={tags === "follow-up"}
                    onChange={(e) => setTags(e.target.value)} 
                />
            </div>
          </CardBody>
          <CardFooter className="pt-0">
            <Button variant="gradient" onClick={handleClick} fullWidth>
              Create Case
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
};
