
import {useQuery} from "react-query";
import {fetchJson} from "../../../../auth/DataProvider" 
import {daysAgo, roundToMinute } from "../../../../utils/datetime";


interface EventQueryProps {
    startTime?: string;
    endTime?: string;
    eventTypes?: string[];
}

export const useEventQuery = ({
    startTime = daysAgo(30).toISOString(), 
    endTime = daysAgo(0).toISOString(),
    eventTypes = ["WARNING", "ERROR"], 
}: EventQueryProps) => {

    const eventTypeQueryStr = eventTypes.map(et => `eventTypes=${et}`).join('&');

    const _startTime = roundToMinute(new Date(startTime));
    const _endTime = roundToMinute(new Date(endTime));

    return useQuery(
        // cache based on datetime range and only to minute level  
        ['event',  _startTime, _endTime, eventTypes],
        // the fetcher function
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/events?start_date=${startTime}&end_date=${endTime}&${eventTypeQueryStr}`,
            { method: 'GET' }
        )
    )
}