import {Box} from '@mui/material'

// Display data in raw json format inside pre tag. 
export const JsonDebug = ({data}:any) => { 
    return ( 
        <Box component="pre" sx={{textAlign : "left"}} >
            <code>{JSON.stringify(data, null, 2)}</code>
        </Box>
    ) 
}

