import { isEmpty } from "lodash";
import React from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Step,
  Stepper,
  Tab,
  Tabs,
  TabsHeader,
  TabsBody,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import { useSessionDetailQuery } from "../../../apis/endpoints/admin/sessionServices";
import { useWBSQuery } from "../../../apis/endpoints/admin/organizationService";
import {
  ArrowDownTrayIcon,
  PlayCircleIcon,
  StopCircleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  QueueListIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";

import {ChatListCard} from "./ChatListCard";
import { ConverstaionCard } from "./ConversationCard";
import { downloadJSON } from "../../../../utils/export";

// get breadcrumbs of current session orgnaization hiearchy
export const OrgBreadcrumbs: React.FC<{wbsNum:string}> =({wbsNum}) => {
  // let's re-use useWBSQuery to get the breadcrumbs, as it may be fetched and cached already, may change later.
  const { data, error, isLoading } = useWBSQuery();
  if(isLoading) return <>...loading</>;
  if(error) return <>"Error: " + error.toString()</>;
  const wbsData: Array<{ [key: string]: any }> = data?.json;
  console.log("wbsData: ", wbsData)
  // find the the wbs item that matched the wbs number passed as param
  const wbsItem = wbsData.find((item) => item.wbs_number === wbsNum);
  if (isEmpty(wbsItem)){ 
    console.log("wbsItem is empty, wbsNum is: ", wbsNum)
    return <>...loading</>;
  }
  console.log("wbsItem: ", wbsItem)

  return (<>
    <Breadcrumbs
      separator={
        <ChevronRightIcon className="h-4 w-4 text-blue-500" strokeWidth={2.5} />
      }
      className="rounded-full "
    >
      <NavLink to={`/dashboard/department/${wbsItem!.project!.department!.id}`}>
        {wbsItem!.project!.department!.name}
      </NavLink>
      <NavLink to={`/dashboard/project/${wbsItem!.project!.id}`}>
        {wbsItem!.project!.name}
      </NavLink>
      <NavLink to={`/dashboard/wbs/`}>
        {wbsItem!.wbs_number} - {wbsItem!.description}
      </NavLink>
    </Breadcrumbs>
  </>) 
}

interface SessionDetailCardProps {
  numOfChats?: number;
  duration?: string;
  totalTokens?: number;
  avgTokensPerChat?: number;
  estimatedCost?: number;
  wbsNumber?: string,
}

export const SessionDurationChart: React.FC<SessionDetailCardProps> = ({
  numOfChats,
  duration,
  totalTokens,
  avgTokensPerChat,
  estimatedCost,
  wbsNumber,
}) => {
  return (
    <Card className="mb-10 bg-gray-50">
      <CardBody>
        <div className="w-full pb-2 px-2">
          <OrgBreadcrumbs wbsNum={wbsNumber!} />
        </div>
        <div className="w-full pt-10 pb-24 px-32">
          <Stepper>
            <Step>
              <PlayCircleIcon className="h-5 w-5" />
              <div className="absolute -bottom-[4.5rem] w-max text-center">
                <Typography variant="h6" color={"blue"}>
                  Started
                </Typography>
                <Typography color={"gray"} className="font-normal">
                  at 2021-10-01 12:00:00
                </Typography>
              </div>
            </Step>
            <Step>
              <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" />
              <div className="absolute -bottom-[4.5rem] w-max text-center">
                <Typography variant="h6" color={"blue-gray"}>
                  Chat Stats
                </Typography>
                <Typography color={"gray"} className="font-normal">
                  Total <strong className="text-green-500">{numOfChats}</strong> chats,
                  in <strong className="text-green-500">{duration}</strong>,
                  total <strong className="text-green-500">{totalTokens} </strong>tokens,
                  average <strong className="text-green-500">{avgTokensPerChat} </strong> tokens/chat,
                  estimated cost: <strong className="text-green-500">{estimatedCost} </strong> cents.
                </Typography>
              </div>
            </Step>
            <Step>
              <StopCircleIcon className="h-5 w-5" />
              <div className="absolute -bottom-[4.5rem] w-max text-center">
                <Typography variant="h6" color={"pink"}>
                  Ended 
                </Typography>
                <Typography color={"gray"} className="font-normal">
                  at 2021-10-01 12:10:00
                </Typography>
              </div>
            </Step>
          </Stepper>
        </div>
      </CardBody>
    </Card>
  );
};

export const SessionDetailCard = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSessionDetailQuery(id! as string);

  if (isLoading) {
    return <>...loading</>;
  }
  if (error) {
    return <>"Error: " + error.toString()</>;
  }

  const chatListData: Array<{ [key: string]: any }> = data?.json;
  const numOfChats = chatListData.length;

  // calcluate total duration
  const firstRecord = new Date(chatListData[0].creation_time);
  const lastRecord = new Date(
    chatListData[chatListData.length - 1].creation_time
  );

  const durationInMs = lastRecord.getTime() - firstRecord.getTime();
  const durationInMinutes = Math.floor(durationInMs / (1000 * 60));
  const durationInSeconds = Math.floor((durationInMs % (1000 * 60)) / 1000);

  const friendlyDuration =
    durationInMinutes > 0
      ? `${durationInMinutes} minutes ${durationInSeconds} seconds`
      : `${durationInSeconds} seconds`;
  
  // calculate total tokens
  const totalTokens = chatListData.reduce((acc, cur) => acc + cur.total_tokens, 0);

  // calculate average tokens per chat
  const avgTokensPerChat = Math.floor(totalTokens / numOfChats);

  // calculate estimated cost, in CENTS
  const estimatedCost = chatListData.reduce((acc, cur) => acc + cur.est_token_cost, 0) * 100; 

  // get wbs number
  const wbsNumber = chatListData[0].wbs_number;

  const handleDownload = ()=> { 
    downloadJSON(chatListData, `session_${id}.json`);
  };

  return (
    <>
      <Card>
        <CardBody>
          <SessionDurationChart
            numOfChats={numOfChats}
            duration={friendlyDuration}
            totalTokens={totalTokens}
            avgTokensPerChat={avgTokensPerChat}
            estimatedCost={estimatedCost}
            wbsNumber={wbsNumber}
          />

        <div className="absolute left-96 mt-1">
          <Button variant="outlined" size="sm" className="flex item gap-3" 
                  onClick={()=> {handleDownload();}}>
            <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" /> Download JSON
          </Button>
        </div>

        <Tabs value="chat table" className="w-full">
              <TabsHeader
                className=" w-80"
                indicatorProps={{
                  className: "bg-blue-500/10 shadow-none text-blue-500",
                }}
              >
                  <Tab key={"chat table"} value={"chat table"}>
                    <div className="flex items-center gap-2">
                      <QueueListIcon className="h-5 w-5" />
                      Chat List 
                    </div>
                  </Tab>
                  <Tab key={"conversation"} value={"conversation"}>
                    <div className="flex items-center gap-2">
                      <ChatBubbleLeftRightIcon className="h-5 w-5" />
                      Conversation 
                    </div>
                  </Tab>

              </TabsHeader>
              <TabsBody>
                  <TabPanel key={"chat table"} value={"chat table"}>
                    <ChatListCard chatList={chatListData} />
                  </TabPanel>
                  <TabPanel key={"conversation"} value={"conversation"}>
                  <ConverstaionCard chatList={chatListData} />
                  </TabPanel>
              </TabsBody>
        </Tabs>

        </CardBody>
      </Card>
    </>
  );
};
