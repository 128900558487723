/**
 * Place to hold utility functions to create ApexChart options, and we should try to keep it as generic as possible.
 */

export const chartsConfig = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  title: {
    show: "",
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      style: {
        colors: "#fff",
        fontSize: "13px",
        fontFamily: "inherit",
        fontWeight: 300,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "13px",
        fontFamily: "inherit",
        fontWeight: 300,
      },
    },
  },
  grid: {
    show: true,
    borderColor: "#ffffff40",
    strokeDashArray: 5,
    xaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 5,
      right: 20,
    },
  },
  fill: {
    opacity: 0.8,
  },
  tooltip: {
    theme: "dark",
  },
};


export const barChartBaseOptions = {
  type: "bar",
  height: 220,
  series: [
    {
      name: "Views",
      data: [],
    },
  ],
  options: {
    ...chartsConfig,
    colors: "#fff",
    plotOptions: {
      bar: {
        columnWidth: "16%",
        borderRadius: 5,
      },
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: [],
    },
  },
};


export const pieChartBaseOptions ={
  type: 'donut',
  width: 500,
  series: [],
  options: {
    chart: {
      type: 'donut',
    },
    labels: [],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  },
};
         

export const lineChartBaseOptions = {
  type: "line",
  height: 220,
  series: [
    {
      name: "Line",
      data: [],
    },
  ],
  options: {
    ...chartsConfig,
    colors: ["#fff"],
    stroke: {
      lineCap: "round",
    },
    markers: {
      size: 5,
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: [
      ],
    },
  },
};


export const createBarChartOptions = (name: string, data: any[], categories: any[]) => {
    // construct options in the immutable way, for now just manually craft it
    // may switch to immer, but it is also not easy to use outside of redux reducer 
    return {
        ...barChartBaseOptions,
        series: [{
            name: name,
            data: data,
        }],
        options: { 
            ...barChartBaseOptions.options,
            xaxis : {
                ...barChartBaseOptions.options.xaxis,
                categories: categories,
            }
        }
    }
}


export const createLineChartOptions = (name: string, data: any[], categories: any[]) => {
    return {
        ...lineChartBaseOptions,
        series: [{
            name: name,
            data: data,
        }],
        options: { 
            ...lineChartBaseOptions.options,
            xaxis : {
                ...lineChartBaseOptions.options.xaxis,
                categories: categories,
            }
        }
    };
}


export const createPieChartOptions = (labels: string[], series: any[]) => {
    return {
        ...pieChartBaseOptions,
        series: series,
        options: {
            ...pieChartBaseOptions.options,
            labels: labels,
        }
    };
}