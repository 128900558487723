import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Avatar,
  Typography,
  Tabs,
  TabsHeader,
  Tab,
  Switch,
  Tooltip,
  Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import {useFetchReport} from "../../apis/endpoints/admin/reportService";

// const makeReportDownloadLink = (reportName: string) => {
//     const baseUrl= import.meta.env.VITE_REACT_APP_API_BASE_URL;
//     return `${baseUrl}/report?name=${reportName}`;
// }

export const Report = () => {

  const fetchReport = useFetchReport();

  const bannerImg =
    "https://images.unsplash.com/photo-1510674485131-dc88d96369b4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=649&q=80";
  // "https://images.unsplash.com/photo-1588495756505-5437763146dd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=688&q=80";
  //   "https://images.unsplash.com/photo-1572314997669-275cf96124fc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=701&q=80";
  //   "https://images.unsplash.com/photo-1558978325-66dcf73b99b0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80";

  const reportData = [
    {
      img: "https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      title: "Sensitive Data Filtering Report",
      tag: "Security",
      description:
        "Sensitive data filtering report, showing recent filtered sensitive, and PII(Personally Identifiable Information) data.\
        Such information include name, address, telephone number, email address, etc.",
      // route: makeReportDownloadLink("pii"),
      report: "pii",
    },
    {
      img: "https://images.unsplash.com/photo-1507878866276-a947ef722fee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80",
      title: "Project Breakdown Report",
      tag: "Operation",
      description:
        "Project breakdown report, showing the number of chat sessions, cross different projects.",
      // route: makeReportDownloadLink("project"), 
      report: "project",
    },
    {
      img: "https://plus.unsplash.com/premium_photo-1679456904315-ecee6665c638?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2127&q=80",
      title: "Cost Analysis Report",
      tag: "Cost",
      description:
        "Cost analysis report, showing the cost of average chat session, cross diffferent projects.",
      // route: makeReportDownloadLink("cost"),
      report: "cost",
    },
    {
      img: "https://images.unsplash.com/photo-1664298908751-36b9449815df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1615&q=80",
      title: "Trending Topics Report",
      tag: "Machine Learning",
      description:
        "Clustering & Trending analysis, showing the most popular topics and keywords in the past 7 days.",
      // route: makeReportDownloadLink("trending"),
      report: "trending",
    },
  ];

  const handleClick = async (name: string) => {
    // invoke the async function to get different report every time it is invoked
    const blob = await fetchReport(name);
    console.log(">>>> blob: ", blob)
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = `report-${name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // need to remove object url to avoid memory leak
  }

  return (
    <>
      {/* work around the bg-[url(https://images.unsplash.com/photo-1555949963-ff9fe0c870eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80)]  */}
      <div
        style={{ backgroundImage: `url(${bannerImg})` }}
        className={`relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-cover bg-center`}
      >
        <div className="absolute inset-0 h-full w-full bg-gray-200/50" />
      </div>
      <Card className="mx-3 -mt-16 mb-6 lg:mx-4">
        <CardBody className="p-4">
          <div className="px-4 pb-4">
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Reports
            </Typography>
            <Typography
              variant="small"
              className="font-normal text-blue-gray-500"
            >
              Reports are generated based on the data collected from the chats.
            </Typography>
            <div className="mt-6 grid grid-cols-1 gap-12 md:grid-cols-2 xl:grid-cols-4">
              {reportData.map(({ img, title, description, tag, report }) => (
                <Card key={title} color="transparent" shadow={false}>
                  <CardHeader
                    floated={false}
                    color="gray"
                    className="mx-0 mt-0 mb-4 h-64 xl:h-40"
                  >
                    <img
                      src={img}
                      alt={title}
                      className="h-full w-full object-cover"
                    />
                  </CardHeader>
                  <CardBody className="py-0 px-1">
                    <Typography
                      variant="small"
                      className="font-normal text-blue-gray-500"
                    >
                      {tag}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="blue-gray"
                      className="mt-1 mb-2"
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="small"
                      className="font-normal text-blue-gray-500"
                    >
                      {description}
                    </Typography>
                  </CardBody>
                  <CardFooter className="mt-6 flex items-center justify-between py-0 px-1">
                    {/* <Link to={route} target="_blank"> */}
                      <Button variant="outlined" size="sm" className="flex item gap-3" 
                              onClick={()=> {handleClick(report)}}>
                        <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" /> Download Report
                      </Button>
                    {/* </Link> */}
                  </CardFooter>
                </Card>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Report;
