import {useQuery} from "react-query";
import {fetchJson} from "../../../../auth/DataProvider" 
import {daysAgo, roundToMinute } from "../../../../utils/datetime";


interface SessionListQueryProps {
    startTime?: string;
    endTime?: string;
}


export const useSessionListQuery = ({
    startTime = daysAgo(30).toISOString(), 
    endTime = daysAgo(0).toISOString(),
}) => {
    const _startTime = roundToMinute(new Date(startTime));
    const _endTime = roundToMinute(new Date(endTime));
    return useQuery(
        ['session', "list", _startTime, _endTime],
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/sessions?start_time=${startTime}&end_time=${endTime}`,
        )
    );
}


export const useSessionDetailQuery = (sessionId: string) => {
    // should we not cache and refetch every 1 minute? Or just fetch and never expired until we choose to?
    const _lastfetch = roundToMinute(new Date());
    return useQuery(
        ['session', sessionId, _lastfetch],
        () => fetchJson(
            `${import.meta.env.VITE_REACT_APP_API_BASE_URL}/session/${sessionId}`,
        )
    );
}