import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
  } from "@material-tailwind/react";

// it is just anonying to have so many different color definition, this one accept addtiona "transparent" string
// have to dig into source code find out, tons of similar union definition.
import type {color} from "@material-tailwind/react/types/components/card";

  // the prop below does not use 'key' but caller seems passing it in.
  export interface StatCardProps {
    key?: string;
    color?: string;
    icon: React.ReactNode;
    title: React.ReactNode;
    value?: React.ReactNode;
    footer?: React.ReactNode;
    // need an index signature to allow props to be passed to the component
    [x: string]: any;
  }
  
  export const StatCard: React.FC<StatCardProps> = ({
    color = "blue",
    icon,
    title,
    value,
    footer = null,
  }) => {
    return (
      <Card>
        <CardHeader
          variant="gradient"
          color={color as color}
          className="absolute -mt-4 grid h-16 w-16 place-items-center"
        >
          {icon}
        </CardHeader>
        <CardBody className="p-4 text-right">
          <Typography variant="small" className="font-normal text-blue-gray-600">
            {title}
          </Typography>
          <Typography variant="h4" color="blue-gray">
            {value}
          </Typography>
        </CardBody>
        {footer && (
          <CardFooter className="border-t border-blue-gray-50 p-4">
            {footer}
          </CardFooter>
        )}
      </Card>
    );
  };
  
  export default StatCard;
  