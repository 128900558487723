import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import { CasesListCard } from "../widgets/cases/CasesListCard";

export const Cases = () => {

    return (
        <>
            <CasesListCard />        
        </>
    )
}