import React, { useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Slider,
  Step,
  Stepper,
  Switch,
  Typography,
} from "@material-tailwind/react";

export const ABTestSetting = () => {
  const [percentages, setPercentages] = useState([100, 0, 0]);

  const handleChange = (index: number, newValue: number) => {
    let maxValue = 100;
    const remaining = maxValue - newValue;
    setPercentages((vs) =>
      vs.map((v, i) => {
        if (i === index) return newValue;
        const oldRemaining = maxValue - vs[index];
        if (oldRemaining) return (remaining * v) / oldRemaining;
        return remaining / (percentages.length - 1);
      })
    );
  };



  return (
    <>
      <div className="gird-cols-1 mb-12 grid gap-12 px-4  p-16 shadow-lg rounded-lg">
        <div className="ml-8">
          <Typography variant="h6" color="blue-gray" className="mb-3">
            A/B Test Settings
          </Typography>

          <div className="mb-8 ">
            <Typography
              variant="mb-4 block text-xs font-semibold  text-blue-gray-500"
              color="blue-gray"
              className="mb-3"
            >
                Percentage of each model in the A/B test. Only use ChatGPT now, as it has stable working APIs. Bard has no official API yet, and Claude2 APIs are just released in early access stage.
            </Typography>
            <div className="flex flex-col gap-4 my-8 w-80">
                <label>ChatGPT: {percentages[0]}%</label>
                <Slider
                color="blue"
                value={percentages[0]}
                onChange={(event) =>
                    handleChange(0, parseInt(event.target.value))
                }
                />
                <label>Claude2: {percentages[0]}%</label>
                <Slider
                color="blue"
                value={percentages[1]}
                onChange={(event) =>
                    handleChange(1, parseInt(event.target.value))
                }
                />
                <label>Bard: {percentages[0]}%</label>
                <Slider
                color="blue"
                value={percentages[2]}
                onChange={(event) =>
                    handleChange(2, parseInt(event.target.value))
                }
                />
            </div>
          </div>
        </div>

        <NavLink to="/dashboard/settings">
          <Button variant="gradient" className="mt-1 ml-6">
            Back
          </Button>
        </NavLink>
      </div>
    </>
  );
};
