import _ from "lodash";

/**
 * Look up dictionary with both keys and values as strings
 */
interface LookupDict {
  [key: string]: string;
}


/**
 * Convert a string to a friendly name, if the string is in the lookup 
 * dictionary, return the value from the dictionary directly, otherwise, 
 * remove underscores and convert initial letter to uppercase, and concatenate
 * them with space.  It is for display purpose, such as table header, etc.,
 * without manually typing the friendly name.
 * 
 *  @param inputStr - the string to be converted
 *  @param lookupDict - the lookup dictionary
 *  @returns the friendly name as string
 */
export const friendlyName = (inputStr: string, lookupDict?: LookupDict) => {

  if (lookupDict && lookupDict[inputStr]) {
    return lookupDict[inputStr];
  }

  const resultStr = _.chain(inputStr)
    .replace(/_/g, " ")
    .startCase()
    .value()
    .replace(/\s/g, " ");

  return resultStr;
};


