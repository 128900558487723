import React from "react";
import { useState } from "react";
import {NavLink} from "react-router-dom";
import { Combobox } from "@headlessui/react";
import {
  Card,
  CardHeader,
  CardBody,
  Dialog,
  Typography,
  Avatar,
  Chip,
  Tooltip,
  Progress,
} from "@material-tailwind/react";

import { useGetSessionListQuery } from "../../../apis/endpoints/admin/organizationService";

interface SessionCardProps {
    wbs?: string;
    projectId?: string;
    departmentId?: string;
    startTime?: string;
    endTime?: string;
}


export const SessionCard: React.FC<SessionCardProps> = ({wbs, projectId, departmentId, startTime, endTime}) => {

    const {data, error, isLoading} = useGetSessionListQuery({wbs, projectId, departmentId, startTime, endTime});

    if (isLoading) {
        return <>...loading</>;
    }
    if (error) {
        return <>"Error: " + error.toString()</>;
    }

    console.log("session data: ", data);

    const chatSessions: Array<{ [key: string]: any }> = data?.json;

    return (
        <>
            <Card>
                <CardBody>

          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                    "Session ID",
                    "Time",
                    "1st Chat Reuest",
                    "1st Chat Response",
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {chatSessions.map((row, index) => {
                const className = `py-3 px-5 ${
                  index === chatSessions.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`;

                return (
                  <tr key={index}>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        <NavLink to={`/dashboard/session/${row!.session_id}`} 
                          className="underline text-blue-500 hover:text-gray-900 px-3 py-2 text-sm font-medium"
                        >
                                  {row.session_id}
                        </NavLink>
              
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.creation_time}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.last_request}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {row.last_response}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

                </CardBody>

            </Card>
        </>
    )
}